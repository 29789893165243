import { IBlock } from "../../../framework/main/IBlock";
import { Message } from "../../../framework/main/Message";
import { BlockComponent } from "../../../framework/main/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/main/Messages/MessageEnum";
import { runEngine } from "../../../framework/main/RunEngine";

import StorageProvider from "../../../framework/main/StorageProvider";
import { yourApproachOnboradingData } from "../../../helpers/onboarding";
import { ONBOARDING_STEPS, STEPS_TYPE } from "../../../utils/enums";
import { getOnboardingData, saveOnboardingData, triggerAutofillOnboarding } from "../../../redux/services/onboarding";
import { enqueueSnackbar } from "notistack";
import { observableService } from "../../../services/observable";

interface MultipleInputItem {
  offeringPlaceholder: string;
  descriptionPlaceholder: string;
  offering: string;
  description: string;
  offeringTitle: string;
  descriptionTitle: string;
  charLength?: number;
  charLengthDesc?: number;
  wordsLength?: number;
  wordsLengthDesc?: number;
}

interface TextAreaInput {
  placeholder: string;
  value: string;
  label: string
  charLength: number
}

export const initialValues: any = {
  whyExist: ["Science-based approach"],
  processStep: ['', ''],
  processDescription: ['', ''],
  background: "",
  problem: "",
  storySolution1: '',
  storySolution2: '',
  storySolution3: '',
  storyResult1: '',
  storyResult2: '',
  storyResult3: '',
  stage1: '',
  stageDescription1: "",
  stage2: '',
  stageDescription2: "",
  stage3: '',
  stageDescription3: "",
  solutionTitle1: '',
  solutionStatement1: "",
  solutionTitle2: '',
  solutionStatement2: "",
  solutionTitle3: '',
  solutionStatement3: "",
  segmentOverview: [""],
  segmentTitle1: '',
  segmentDescription1: '',
  segmentTitle2: '',
  segmentDescription2: '',
  segmentTitle3: '',
  segmentDescription3: '',
  processSection: [''],
  compareSection: ['']
}
const chipDatas = ["Science-based approach"]

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  token?: string;
  autoFillEmitter?: any;
  setAutoSaveData?: any;
  autoSavedValues?: any;
  formRef?: any;
  setFormRef?: any;
  hideBackBtn?: any;
  closeTooltip?: (section: string, key: string) => void;
  tooltips?: any;
}

interface S {
  serviceArray: string[];
  serviceArray1: string[];
  shouldHaveServiceArray: string[];
  inputList: any;
  activeStep: any;
  prevCompletedStep: any;
  mustHaveExpanded: boolean;
  shouldHaveExpanded: boolean;
  goodToHaveExpanded: boolean;
  chipLabels: any;
  alreadyFilledFormValues: any;
  successStoriesInputList: (TextAreaInput & { name: string })[];
  successStoriesServiceArr: any;
  companyHeadquarterInputList: any;
  segmentExpertiseServiceArr: any;
  primaryQuestionInputList: any;
  primaryQuestionServiceArr: any;
  companyAchievementsInputList: any;
  companyAchievementsServiceArr: any;
  companyFactsInputList: any;
  companyFactsServiceArr: any;
  segmentExpertiseInputList: TextAreaInput[];
  offeringsServiceArray: string[];
  offeringsInputList: MultipleInputItem[],
  headerSubStep: number;
  theProcessInputList: MultipleInputItem[],
  approachServiceArray: string[];
  approachInputList: MultipleInputItem[],
  storySolutionInputList: TextAreaInput[],
  storyResultInputList: TextAreaInput[],
  keySegmentInputList: MultipleInputItem[];
  keySegmentServiceArray: any;
  highLevelComparisonServiceArr: any;
  mqlServiceArr: any;
  highLevelComparisonInputList: any;
  mqlStatementInputList: any;
  contributionInputList: any;
  loading: boolean;
  clearAllInfoDialog: boolean;
  skipModalOpen: boolean;
  setAutoSaveData?: any;
  formRef?: any;
  showUploadDocumentsModal?: boolean;
}

interface SS {
  id: any;
}

export default class AboutYourApproachController extends BlockComponent<
  Props,
  S,
  SS
> {
  dataFromAPIMsgID: string = '';
  completedSteps: any;
  token: string;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.receive = this.receive.bind(this);

    this.state = {
      headerSubStep: 1,
      alreadyFilledFormValues: {},
      mustHaveExpanded: true,
      shouldHaveExpanded: false,
      goodToHaveExpanded: false,
      activeStep: 2,
      prevCompletedStep: 1,
      loading: false,
      chipLabels: chipDatas,
      clearAllInfoDialog: false,
      skipModalOpen: false,
      storySolutionInputList: [
        {
          placeholder: 'Strategic Roadmap: crafted a specialised go-to-market plan, tailored for their company’s success.',
          value: '',
          label: 'Point',
          charLength: 400,
        },
        {
          placeholder: 'Deep Market Intelligence: gained a comprehensive understanding of their target markets.',
          value: '',
          label: 'Point',
          charLength: 400,
        },
        {
          placeholder: 'Qualified Team Support: introduced a team of seasoned professionals to provide strategic guidance and execution.',
          value: '',
          label: 'Point',
          charLength: 400,
        },
      ],

      storyResultInputList: [
        {
          placeholder: 'The sales cycle drastically shortened from 9 months to 4 weeks.',
          value: '',
          label: 'Point',
          charLength: 400,
        },
        {
          placeholder: 'They witnessed a 6X revenue increase within 12 months. ',
          value: '',
          label: 'Point',
          charLength: 400,
        },
        {
          placeholder: 'The company expanded its offering to 3 different geolocations in a rapid expansion move.',
          value: '',
          label: 'Point',
          charLength: 400,
        },
      ],

      theProcessInputList: [
        {
          offeringPlaceholder: "Mission Brief: gather and review client intelligence.",
          descriptionPlaceholder: "Description of step 1",
          offering: '',
          description: '',
          offeringTitle: 'Step N',
          descriptionTitle: 'Description',
          charLength: 120,
          charLengthDesc: 280
        },
        {
          offeringPlaceholder: "Marketing Strategy: produce GTMS™ Source of Truth marketing strategy.",
          descriptionPlaceholder: "Description of step 2",
          offering: '',
          description: '',
          offeringTitle: 'Step N',
          descriptionTitle: 'Description',
          charLength: 120,
          charLengthDesc: 280
        }
      ],

      offeringsServiceArray: ['Min 1 solution statement', 'Title length max 30 words', 'Description length max 300 words'],

      offeringsInputList: [
        {
          offeringPlaceholder: "Levelling the GTM Field",
          descriptionPlaceholder: "We democratise B2B/B2C success using scientific frameworks, industry best practices, and advanced machine learning with a singular focus on GTM. Pebbles Ai is an AI-native, end-to-end GTM platform that supercharges your productivity, propels your business growth, and ensures your long-term success.",
          offering: '',
          description: '',
          offeringTitle: 'Solution Title',
          descriptionTitle: 'Solution Statement',
          wordsLength: 30,
          wordsLengthDesc: 300
        },
        {
          offeringPlaceholder: "Harnessing GTM Wisdom",
          descriptionPlaceholder: "With our science-based go-to-market wisdom and multi-layer AI architecture, your business can now access its own centralised GTM cognition. Our intelligence as a service offers a comprehensive suite of GTM solutions complete with cross-platform integrations and open APIs for corporate partners and clients alike.",
          offering: '',
          description: '',
          offeringTitle: 'Solution Title',
          descriptionTitle: 'Solution Statement',
          wordsLength: 30,
          wordsLengthDesc: 300
        },
        {
          offeringPlaceholder: "Boosting GTM Workflows",
          descriptionPlaceholder: "We harness multi-disciplinary sciences to supercharge your MarkSales workflows, letting GTM teams achieve peak productivity and maximise efficacy. This highly personalised, knowledgeable GTM co-pilot is an indispensable intelligence & content generator for any GTM professional.",
          offering: '',
          description: '',
          offeringTitle: 'Solution Title',
          descriptionTitle: 'Solution Statement',
          wordsLength: 30,
          wordsLengthDesc: 300
        },
      ],

      approachServiceArray: ['Min 1 stage', 'Stage length max 30 words', 'Description length max 150 words each'],
      approachInputList: [
        {
          offeringPlaceholder: "Marketing Strategy",
          descriptionPlaceholder: "Effectively bring your products and services to market with a strategic go-to-market roadmap to business success from market entry to expansion.",
          offering: '',
          description: '',
          offeringTitle: 'Stage',
          descriptionTitle: 'Description',
          wordsLength: 30,
          wordsLengthDesc: 150
        },
        {
          offeringPlaceholder: "Market Intelligence",
          descriptionPlaceholder: "We acquire a comprehensive understanding of your markets covering industry segments, geolocations, persona clusters, and deep persona analyses.",
          offering: '',
          description: '',
          offeringTitle: 'Stage',
          descriptionTitle: 'Description',
          wordsLength: 30,
          wordsLengthDesc: 150
        },
        {
          offeringPlaceholder: "Automated MarTech",
          descriptionPlaceholder: "We generate significantly more marketing-qualified leads (MQLs) at lower operational costs with automation-driven marketing execution.",
          offering: '',
          description: '',
          offeringTitle: 'Stage',
          descriptionTitle: 'Description',
          wordsLength: 30,
          wordsLengthDesc: 150

        },
      ],

      keySegmentServiceArray: ["Max 3 differentiators", "Title length max 15 characters", "Description length max 100 characters"],
      keySegmentInputList: [
        {
          offeringPlaceholder: "Science-Led",
          descriptionPlaceholder: "Strategy models that incorporate multi-disciplinary sciences specifically developed for young enterprises.",
          offering: '',
          description: '',
          offeringTitle: 'Differentiator',
          descriptionTitle: '',
          charLength: 60,
          charLengthDesc: 400
        },
        {
          offeringPlaceholder: "Innovative",
          descriptionPlaceholder: "Cutting-edge methodologies that have been empirically researched, tested, and applied for real-world usage.",
          offering: '',
          description: '',
          offeringTitle: 'Differentiator',
          descriptionTitle: '',
          charLength: 60,
          charLengthDesc: 400
        },
        {
          offeringPlaceholder: "Automated",
          descriptionPlaceholder: "Marketing execution that reduces GTM-related costs & dramatically increases incoming qualified leads.",
          offering: '',
          description: '',
          offeringTitle: 'Differentiator',
          descriptionTitle: '',
          charLength: 60,
          charLengthDesc: 400

        },
      ],

      segmentExpertiseInputList: [
        {
          label: "",
          placeholder: "We serve various sectors, but have particular experience in Tech, Web3, IoT, FMCG, Healthcare, Business and Financial Services.Whether you run a lean 10-person team or a more established 1000-strong business, we bring expertise and tools to set you up for success.",
          value: "",
          charLength: 1200

        },
      ],

      successStoriesInputList: [
        {
          label: "Background",
          placeholder: "In the competitive B2B/B2BC sector, a majority of SMEs struggle due to untapped elite GTM strategies and a vast knowledge chasm.",
          value: "",
          name: "background",
          charLength: 520

        },
        {
          label: "Problem",
          placeholder: "Our customer despite their ambition, grappled with an extended sales cycle, and slow revenue growth.",
          value: "",
          name: "problem",
          charLength: 400

        }
      ],

      successStoriesServiceArr: ["Background length max 520 characters", "Problem length max 400 characters each", "Solution length max 400 characters each", "Results length max 400 characters each"],

      companyHeadquarterInputList: [
        {
          label: "",
          placeholder: "Headquartered in London, we are Europe’s science-driven Marketing Strategy House that has easy access to top-qualified talent.",
          value: "",
          name: "",
          charLength: 150

        },
      ],

      segmentExpertiseServiceArr: ["Max 1200 characters "],

      highLevelComparisonServiceArr: ["Max 1300 characters "],

      mqlServiceArr: ["Max 200 characters "],

      highLevelComparisonInputList: [
        {
          label: "",
          placeholder: "Unlike typical agencies, our foundation is scientific, not creative. Blending psychology, neuromarketing, and data science, we offer formidable advantages. Our edge lies in our proprietary strategy models, cutting-edge GTM technologies, and our elite, seasoned team.",
          value: "",
          name: "",
          charLength: 1300

        },
      ],

      mqlStatementInputList: [
        {
          label: "",
          placeholder: "We are an enterprise-grade Marketing Strategy House that is specialised in taking small and medium-sized enterprises to market through our science-led marketing strategies, market intel, and marketing automation.",
          value: "",
          name: "",
          charLength: 200

        },
      ],

      contributionInputList: [
        {
          label: "",
          placeholder: "We commit to using part of our profits to support children in Ukrainian orphanages by supplying them with educational toys and musical instruments, aiding the development of their spatial reasoning, numeracy skills, and literacy skills.",
          value: "",
          name: "",
          charLength: 200

        },
      ],

      companyAchievementsInputList: [
        {
          label: "Testimonial",
          placeholder: "Carl-Henrik Johnson | VP of Sales | Clausematch | B2B SaaS | FinTech The GTMS provided us with a clear, persona-based sales approach with a laser-like focus, which resulted in landing a multinational bank in our 9th month and seeing an overall increase in revenue by 450% YoY.",
          value: "",
          name: "testimonial1",
          charLength: 640
        },
      ],

      companyAchievementsServiceArr: [
        "Include name", 
        "Include title", 
        "Include company name", 
        "Include industry or sector", 
        "Include size of company",
        "Max 3 testimonials",
        "Max 640 characters each",
      ],

      companyFactsInputList: [
        {
          label: "Fact",
          placeholder: "12 industries of combined knowledge",
          value: "",
          name: "fact1",
          charLength: 50
        },
      ],

      companyFactsServiceArr: ["Title ",
        "Company",
        "Industry",
        "Technology",
        "Max 5 testimonials",
        "Max 50 characters each"],

      primaryQuestionInputList: [
        {
          label: "Question",
          placeholder: "Feeling anxious about the growth of your company?",
          value: "",
          name: "question1",
          charLength: 30
        },
        {
          label: "Question",
          placeholder: "In need of a growth partner that you can truly rely on?",
          value: "",
          name: "question2",
          charLength: 30
        },
        {
          label: "Question",
          placeholder: "Worrying about the efficacy of your marketing spend?",
          value: "",
          name: "question3",
          charLength: 30
        }
      ],

      primaryQuestionServiceArr: ['Max 3 questions', ' Max 50 characters each'],

      shouldHaveServiceArray: ["Max 10 steps", "Title length max 120 characters", "Description length max 280 characters"],

      serviceArray: ['Max 3 questions', 'Max 50 characters each'],

      serviceArray1: ['Min 10 and max 20 designations', 'Max 3 words for each designation'],

      inputList: [
        {
          label: "Step",
          placeholder: "Mission Brief: gather and review client intelligence.",
          value: "",
          name: "step1",
          charLength: 30
        },
        {
          label: "Step",
          placeholder: "Marketing Strategy: produce GTMS™ Source of Truth marketing strategy.",
          value: "",
          name: "step2",
          charLength: 70

        },
      ],
      setAutoSaveData: null,
      formRef: null,
      showUploadDocumentsModal: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    super.componentDidMount();
    this.props.autoFillEmitter(this.getDataFromAPI);

    const token = await StorageProvider.getItem("user-token");
    this.token = token;

    this.setQueryParam('partOfStep', 'mustHaveExpanded');

    this.setState({
      mustHaveExpanded: true,
      shouldHaveExpanded: false,
      goodToHaveExpanded: false,
      headerSubStep: 1
    });

    this.fetchInitData();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS): void {
    if (
      (prevState.mustHaveExpanded !== this.state.mustHaveExpanded) ||
      (prevState.shouldHaveExpanded !== this.state.shouldHaveExpanded) ||
      (prevState.goodToHaveExpanded !== this.state.goodToHaveExpanded)
    ) {
      const {
        mustHaveExpanded,
        shouldHaveExpanded,
        goodToHaveExpanded
      } = this.state;
      const trueKey = Object
        .keys({
          mustHaveExpanded,
          shouldHaveExpanded,
          goodToHaveExpanded
        })
        .find(key => this.state[key] === true);

      this.setQueryParam('partOfStep', trueKey || 'goodToHaveExpanded');
    }
  }

  setQueryParam = (key, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.replaceState({}, '', url);
  };

  openSkipConfirmationDialog = () => {
    this.setState({
      skipModalOpen: true
    })
  };

  closeSkipConfirmationDialog = () => {
    this.setState({
      skipModalOpen: false
    })
  };

  async redirectToPage(path: string) {
    await StorageProvider.removeItem('isEdit');

    this.props.navigation.navigate(path);
  };

  clearAllInformation = async (values: any) => {
    this.handleExpandSection('none');

    await this.mustHaveOnSubmit(values, true);
    await this.shouldHaveOnSubmit(values, true);
    await this.goodToHaveOnSubmit(values, true);

    this.setState({ alreadyFilledFormValues: {} });

    this.setState({
      loading: true
    });

    setTimeout(() => {
      this.handleExpandSection('mustHaveExpanded');

      enqueueSnackbar(
        `Data has been cleared successfully`,
        { variant: 'success' }
      );

      this.setState({
        loading: false
      });
    }, 2000);
  };

  saveAndContinue = async (values: any, shadowSave?: boolean, entireSaving?: boolean) => {
    if (entireSaving) {
      this.setState({
        loading: true
      })

      await this.mustHaveOnSubmit(values, shadowSave);
      await this.shouldHaveOnSubmit(values, shadowSave);
      await this.goodToHaveOnSubmit(values, shadowSave);

      this.setState({
        loading: false
      })
    } else {
      if (this.state.mustHaveExpanded) {
        this.mustHaveOnSubmit(values, shadowSave);
      } else if (this.state.shouldHaveExpanded) {
        this.shouldHaveOnSubmit(values, shadowSave);
      } else {
        this.goodToHaveOnSubmit(values, shadowSave);
      }
    }
  };

  handleExpandSection = (targetSection) => {
    this.setState({
      mustHaveExpanded: targetSection === 'mustHaveExpanded',
      shouldHaveExpanded: targetSection === 'shouldHaveExpanded',
      goodToHaveExpanded: targetSection === 'goodToHaveExpanded',
    })
  }

  handleCommonObjectChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    listName: keyof S, // Use keyof to specify the list name
    propertyName: string
  ) => {
    const { value } = event.target;
    this.setState((prevState): any => {
      const updatedList = [...prevState[listName]];
      updatedList[index][propertyName] = value;
      return { [listName]: updatedList };
    });
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson) {
        switch (apiRequestCallId) {
          case this.dataFromAPIMsgID:
            this.handleReplaceDataFromAPI(responseJson)
            break;
        }
      }
    }

    this.setState({
      loading: false
    });
  }

  handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => {
    const { name, value } = event.target;
    const list: any = [...this.state.inputList];
    list[index][name] = value;
    this.setState({ inputList: list })
  }
  handleExpand = () => {
    this.setState({
      mustHaveExpanded: !this.state.mustHaveExpanded,
      shouldHaveExpanded: false,
      goodToHaveExpanded: false,
      headerSubStep: 1
    });
  }
  handleExpand1 = () => {
    this.setState({
      mustHaveExpanded: false,
      shouldHaveExpanded: !this.state.shouldHaveExpanded,
      goodToHaveExpanded: false,
      headerSubStep: 2
    });
  }
  handleExpand2 = () => {
    this.setState({
      mustHaveExpanded: false,
      shouldHaveExpanded: false,
      goodToHaveExpanded: !this.state.goodToHaveExpanded,
      headerSubStep: 3
    });
  }
  deleteChipByName = (nameToDelete: any) => {
    this.setState((prevState) => ({
      chipLabels: prevState.chipLabels.filter((label: any) => label !== nameToDelete),
    }));
  };
  addChipLabels = (value: string) => {
    const { chipLabels } = this.state;
    if (value.trim() !== '') {
      // Create a new array with the new item added to the end
      const updatedItems = [...chipLabels, value];
      this.setState({
        chipLabels: updatedItems,
      });
    }
  }
  handleFormikChange = (
    setFieldValue: (key: string, value: any) => void,
    value: string,
    index: number,
    key: string,
    oldListValue: string[]
  ) => {
    const newListValue = oldListValue.map((_value, idx) => {
      if (idx === index) return value;
      return _value
    })
    setFieldValue(key, newListValue)
  }
  checkValidation = (touch: boolean, error: any) => {
    return !!touch && !!error ? error : ''
  }

  addProcess = () => {
    const { theProcessInputList } = this.state;
    // Create a new array with the new item added to the end
    const updatedItems = [...theProcessInputList, {
      offeringPlaceholder: "",
      descriptionPlaceholder: "",
      offering: '',
      description: '',
      offeringTitle: 'Step N',
      descriptionTitle: 'Description',
      charLength: 120,
      charLengthDesc: 280
    }];
    this.setState({
      theProcessInputList: updatedItems,
    });
  }
  handleAddItem = (value: any) => {
    const { inputList } = this.state;
    if (value.placeholder.trim() !== '') {
      // Create a new array with the new item added to the end
      const updatedInputList = [...inputList, value];
      this.setState({
        inputList: updatedInputList,
      });
    }
  }

  fetchInitData = async () => {
    const { data } = await getOnboardingData();

    if (data && Array.isArray(data)) {
      const {
        mustHaveSection,
        shouldHaveSection,
        goodHaveSection
      } = yourApproachOnboradingData(data);

      const alreadyFilledFormValues = {
        ...initialValues,
        ...mustHaveSection,
        ...shouldHaveSection,
        ...goodHaveSection
      };

      this.state.formRef?.setValues(alreadyFilledFormValues);
      this.state.formRef?.validateForm();

      this.setState({
        chipLabels: mustHaveSection.whyExist || chipDatas,
        alreadyFilledFormValues,
      })
    }
  }

  checkIfValidValue = (key: string, cond: any, value: any) => {
    return cond ? value : initialValues[key];
  }

  checkValidString = (data: any, position: number, key: string) => {
    try {
      return data[position][key];
    } catch (error) {
      return ''
    }
  }

  handleReplaceDataFromAPI = (jsonData: any) => {
    this.setState({
      loading: false
    });

    if (!jsonData.errors && !jsonData.error) {
      const mustHaveSection = {
        solutionTitle1: this.checkValidString(jsonData.SolutionStatements, 0, 'title_1'),
        solutionStatement1: this.checkValidString(jsonData.SolutionStatements, 0, 'description_1'),
        solutionTitle2: this.checkValidString(jsonData.SolutionStatements, 1, 'title_2'),
        solutionStatement2: this.checkValidString(jsonData.SolutionStatements, 1, 'description_2'),
        solutionTitle3: this.checkValidString(jsonData.SolutionStatements, 2, 'title_3'),
        solutionStatement3: this.checkValidString(jsonData.SolutionStatements, 2, 'description_3'),
        stage1: this.checkValidString(jsonData.HighLevelApproach, 0, 'stage_1'),
        stageDescription1: this.checkValidString(jsonData.HighLevelApproach, 0, 'description_1'),
        stage2: this.checkValidString(jsonData.HighLevelApproach, 1, 'stage_2'),
        stageDescription2: this.checkValidString(jsonData.HighLevelApproach, 1, 'description_2'),
        stage3: this.checkValidString(jsonData.HighLevelApproach, 2, 'stage_3'),
        stageDescription3: this.checkValidString(jsonData.HighLevelApproach, 2, 'description_3'),
      }

      const shouldHaveSection = {
        processStep: this.checkIfValidValue('processStep', jsonData.process.length, jsonData.process.map((i: any, index: number) => i[`step_${index + 1}`])),
        processDescription: this.checkIfValidValue('processDescription', jsonData.process.length, jsonData.process.map((i: any, index: number) => i[`description_${index + 1}`])),
        processSection: jsonData.ProcessTestimonials,
        background: [jsonData.SuccessStories.Background],
        problem: [jsonData.SuccessStories.Problem],
        storySolution1: jsonData.SuccessStories.Solution.Point1,
        storySolution2: jsonData.SuccessStories.Solution.Point2,
        storySolution3: jsonData.SuccessStories.Solution.Point3,
        storyResult1: jsonData.SuccessStories.Results.Point1,
        storyResult2: jsonData.SuccessStories.Results.Point2,
        storyResult3: jsonData.SuccessStories.Results.Point3
      }

      const goodHaveSection = {
        segmentOverview: [jsonData.SegmentExpertiseOverview],
        segmentTitle1: this.checkValidString(jsonData.KeySegmentDifferentiators, 0, 'title_1'),
        segmentTitle2: this.checkValidString(jsonData.KeySegmentDifferentiators, 1, 'title_2'),
        segmentTitle3: this.checkValidString(jsonData.KeySegmentDifferentiators, 2, 'title_3'),
        segmentDescription1: this.checkValidString(jsonData.KeySegmentDifferentiators, 0, 'description_1'),
        segmentDescription2: this.checkValidString(jsonData.KeySegmentDifferentiators, 1, 'description_2'),
        segmentDescription3: this.checkValidString(jsonData.KeySegmentDifferentiators, 2, 'description_3'),
        compareSection: [jsonData.HighLevelComparison],
      }

      this.setState({
        goodToHaveExpanded: true,
        chipLabels: this.state.chipLabels || chipDatas,
        shouldHaveExpanded: true,
        alreadyFilledFormValues: {
          ...initialValues,
          ...this.state.alreadyFilledFormValues,
          ...mustHaveSection,
          ...shouldHaveSection,
          ...goodHaveSection,
        },
        mustHaveExpanded: true,
      })
    } else {
      this.parseApiErrorResponse(jsonData);
    }
  }

  getDataFromAPI = async () => {
    enqueueSnackbar(
      `We are preparing AI. It could take some time. Don't refresh and don't leave the page.`,
      { variant: 'info' }
    );

    this.setState({
      mustHaveExpanded: false,
      shouldHaveExpanded: false,
      goodToHaveExpanded: false,
      headerSubStep: 1
    });
    
    this.setState({
      loading: true
    })

    const { data, error } = await triggerAutofillOnboarding({ types: 'AC_APPROACH' });

    if (!data || error) {
      if (error?.status === 402) {
        observableService.sendEvent('Show top up modal for ONBOARDING');
      } else if (error?.status === 422) {
        enqueueSnackbar(
          `Please, upload the documents or provide website URL`,
          { variant: 'info' }
        );

        this.setState({
          showUploadDocumentsModal: true
        })
      } else {
        enqueueSnackbar(
          `Something went wrong. Please try again.`,
          { variant: 'error' }
        );
      }

      this.setState({
        mustHaveExpanded: true,
        shouldHaveExpanded: false,
        goodToHaveExpanded: false,
        headerSubStep: 1
      });
    } else if (Array.isArray(data)) {
      observableService.sendEvent('Decrease usage amount');
      
      enqueueSnackbar(
        `About your approach auto-filled successfully`,
        { variant: 'success' }
      );

      const alreadyFilledFormValues = {
        whyExist: [],
        solutionTitle1: "",
        solutionStatement1: "",
        solutionTitle2: "",
        solutionStatement2: "",
        solutionTitle3: "",
        solutionStatement3: "",
        stage1: "",
        stageDescription1: "",
        stage2: "",
        stageDescription2: "",
        stage3: "",
        stageDescription3: "",
        processStep: [],
        processSection: [""],
        processDescription: [],
        compareSection: [""],
        background: "",
        problem: "",
        storySolution1: "",
        storySolution2: "",
        storySolution3: "",
        storyResult1: "",
        storyResult2: "",
        storyResult3: "",
        segmentOverview: [""],
        segmentTitle1: "",
        segmentDescription1: "",
        segmentTitle2: "",
        segmentDescription2: "",
        segmentTitle3: "",
        segmentDescription3: "",
      };

      data.forEach(item => {
        if (item.parameter_name === 'how_semantic_keywords') {
          alreadyFilledFormValues.whyExist = item.value || []
        }
        if (item.parameter_name === 'solution_statement') {
          item.value.forEach((item, index) => {
            alreadyFilledFormValues[`solutionTitle${index + 1}`] = item.title || item.name
            alreadyFilledFormValues[`solutionStatement${index + 1}`] = item.description
          })
        }
        if (item.parameter_name === 'high_level_approach') {
          item.value.forEach((item, index) => {
            alreadyFilledFormValues[`stage${index + 1}`] = item.title || item.name
            alreadyFilledFormValues[`stageDescription${index + 1}`] = item.description
          })
        }
        if (item.parameter_name === 'process') {
          item.value.forEach((item, index) => {
            alreadyFilledFormValues[`stage${index + 1}`] = item.title || item.name
            alreadyFilledFormValues[`stageDescription${index + 1}`] = item.description
          })
        }
        if (item.parameter_name === 'high_level_comparison') {
          alreadyFilledFormValues.compareSection = Array.isArray(item.value) ? item.value : [`${item.value}`]
        }
        if (item.parameter_name === 'process_testimonial') {
          alreadyFilledFormValues.compareSection = item.value.length
            ? Array.isArray(item.value) 
              ? item.value 
              : [`${item.value}`]
            : ['']
        }
        if (item.parameter_name === 'segment_expertise_overview') {
          alreadyFilledFormValues.segmentOverview = Array.isArray(item.value) ? item.value : [`${item.value}`]
        }
        if (item.parameter_name === 'key_segment_differentiator') {
          item.value.forEach((item, index) => {
            alreadyFilledFormValues[`segmentTitle${index + 1}`] = item.title || item.name
            alreadyFilledFormValues[`segmentDescription${index + 1}`] = item.description
          })
        }
        // if (item.parameter_name === 'success_story') {
        //   item.value.forEach(item => {
        //     alreadyFilledFormValues.background = item.background,
        //     alreadyFilledFormValues.problem = item.problem,
        //     item.solutions.forEach((item, index) => {
        //       alreadyFilledFormValues[`storySolution${index}`] = item
        //     })
        //     (Array.isArray(item.results) 
        //       ? item.results 
        //       : [`${item.results}`]
        //     ).forEach((item, index) => {
        //         alreadyFilledFormValues[`storyResult${index}`] = item
        //       })
        //   })
        // }
      })

      this.state.setAutoSaveData('approach', alreadyFilledFormValues);
      this.state.formRef.setValues(alreadyFilledFormValues);
      this.state.formRef.validateForm();

      // if (!Object.keys(this.state.formRef?.errors)?.length) {
      //   this.saveAndContinue(alreadyFilledFormValues, true, true);
      // }

      this.setState({
        mustHaveExpanded: true,
        shouldHaveExpanded: false,
        goodToHaveExpanded: false,
        headerSubStep: 1
      });

      // this.setState({
      //   alreadyFilledFormValues: alreadyFilledFormValues,
      // })
    }

    this.setState({
      loading: false
    })
  }


  handleObjectChange = (event: any, index: any) => {
    const { value } = event.target;
    const { inputList } = this.state;

    // Create a copy of the inputList array and update the specific item
    const updatedInputList = [...inputList];
    updatedInputList[index]["value"] = value;

    this.setState({
      inputList: updatedInputList
    });
  }
  handleAddObjectItem = () => {
    const { inputList } = this.state;

    // Create a new item and add it to the inputList
    const newItem = {
      label: "Step",
      placeholder: "New Placeholder",
      value: "",
      name: `step${inputList.length + 1}`,
      charLength: 30
    };

    this.setState({
      inputList: [...inputList, newItem]
    });
  }
  handleObjectChangeCompanyAcheivement = (event: any, index: any) => {
    const { value } = event.target;
    const { companyAchievementsInputList } = this.state;

    // Create a copy of the inputList array and update the specific item
    const updatedInputList = [...companyAchievementsInputList];
    updatedInputList[index]["value"] = value;

    this.setState({
      companyAchievementsInputList: updatedInputList
    });
  }
  handleAddObjectItemCompanyAcheivement = () => {
    const { companyAchievementsInputList } = this.state;

    // Create a new item and add it to the inputList
    const newItem = {
      label: "Testimonial",
      placeholder: "Published in Europe’s marketing bestseller book by Oxford University Press in 2022",
      value: "",
      name: `testimonial${companyAchievementsInputList.length + 1}`,
      charLength: 30
    };

    this.setState({
      companyAchievementsInputList: [...companyAchievementsInputList, newItem]
    });
  }

  backButton = async () => {
    const isEdit = await StorageProvider.getItem('isEdit')
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      !!isEdit ? "LandingPage" : "BulkUploading"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  async mustHaveOnSubmit(formValues: any, shadowSave?: boolean) {
    this.setState({
      loading: !shadowSave
    });

    const body = {
      details_type: "must",
      object_type: "approach",
      how_keywords: formValues.whyExist,
      solution_statement: [
        {
          title: formValues.solutionTitle1,
          description: formValues.solutionStatement1,
        },
        {
          title: formValues.solutionTitle2,
          description: formValues.solutionStatement2,
        },
        {
          title: formValues.solutionTitle3,
          description: formValues.solutionStatement3,
        }
      ],
      high_level_approach: [
        {
          title: formValues.stage1,
          description: formValues.stageDescription1,
        },
        {
          title: formValues.stage2,
          description: formValues.stageDescription2,
        },
        {
          title: formValues.stage3,
          description: formValues.stageDescription3,
        }
      ]
    };

    const { data } = await saveOnboardingData(body);

    if (data) {
      if (!shadowSave) {
        this.handleExpandSection('shouldHaveExpanded');
      }
    } else {
      if (!shadowSave) {
        enqueueSnackbar(
          `Something went wrong. Please try again.`,
          { variant: 'error' }
        );
      }
    }

    this.setState({
      loading: false
    });
  }

  async shouldHaveOnSubmit(formikValues: any, shadowSave?: boolean) {
    this.setState({
      loading: !shadowSave
    });

    const body = {
      details_type: "should",
      object_type: "approach",
      process: formikValues.processStep.map((value: string, index: number) => ({
        title: value,
        description: formikValues.processDescription[index],
      })),
      process_testimonials_attributes: formikValues.processSection.map((value: string) => ({
        testimonial: value
      })),
      success_stories: [{
        background: formikValues.background,
      }, {
        problem: formikValues.problem
      }],
      solution: this.state.storySolutionInputList.map((_, index) => ({
        point: formikValues[`storySolution${index + 1}`]
      })),
      results: this.state.storyResultInputList.map((_, index) => ({
        point: formikValues[`storyResult${index + 1}`]
      }))
    };

    const { data } = await saveOnboardingData(body);

    if (data) {
      if (!shadowSave) {
        this.handleExpandSection('goodToHaveExpanded');
      }
    } else {
      if (!shadowSave) {
        enqueueSnackbar(
          `Something went wrong. Please try again.`,
          { variant: 'error' }
        );
      }
    }

    this.setState({
      loading: false
    });
  }

  async goodToHaveOnSubmit(formikValues: any, shadowSave?: boolean) {
    this.setState({
      loading: !shadowSave
    });

    const body = {
      object_type: "approach",
      details_type: "good",
      segment_expertise_overiew: [formikValues.segmentOverview],
      key_segment_differentiators: this.state.keySegmentInputList.map((_, index) => ({
        title: formikValues[`segmentTitle${index + 1}`],
        description: formikValues[`segmentDescription${index + 1}`],
      })),
      high_level_comparison: formikValues.compareSection,
    };

    const { data } = await saveOnboardingData(body);

    if (data) {
      if (!shadowSave) {
        this.props.navigation.navigate('/onboarding/offering-the-company');

        enqueueSnackbar(
          `Thank you for completing Your Approach section! Now, let's add some information about Your Offerings.`,
          { variant: 'success' }
        );
      }
    } else {
      if (!shadowSave) {
        enqueueSnackbar(
          `Something went wrong. Please try again.`,
          { variant: 'error' }
        );
      }
    }

    this.setState({
      loading: false
    });
  }

  closeClearAllInfoDilog = () => {
    this.setState({ clearAllInfoDialog: false })
  }

  openClearAllInfoDilog = () => {
    this.setState({
      clearAllInfoDialog: true
    })
  }

  redirectToStep = (url) => {
    this.props.navigation.navigate(url);
  }
}
