import { api, apiCall } from "../api";
import { ApiResponse } from "../types";

const authenticationAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    loginUser: builder.query<any, any>({
      query: (body) => ({
        url: `/bx_block_login/logins`,
        method: "POST",
        body
      })
    }),
    signUpUser: builder.query<any, any>({
      query: (body) => ({
        url: `/account_block/accounts`,
        method: "POST",
        body
      })
    }),
    sendResetPasswordLink: builder.query<any, any>({
      query: (body) => ({
        url: `/bx_block_forgot_password/passwords`,
        method: "POST",
        body
      })
    }),
    saveNewPassword: builder.query<any, any>({
      query: ({ params, body }) => ({
        url: `/resetpassword?token=${params.token}&email=${params.email}`,
        method: "PATCH",
        body
      })
    }),
    sendOtpCode: builder.query<any, any>({
      query: (body) => ({
        url: `/account_block/accounts/resent_signup_otp`,
        method: "POST",
        body
      })
    }),
    confirmOtpCode: builder.query<any, any>({
      query: (body) => ({
        url: `/otp_confirmation`,
        method: "POST",
        body
      })
    }),
    updateAccountDetails: builder.query<any, any>({
      query: (body) => ({
        url: `/account_block/accounts/update_account`,
        method: "POST",
        body
      })
    }),
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const loginUser = async (body): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.loginUser, body);
};

export const signUpUser = async (body): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.signUpUser, body);
};

export const sendResetPasswordLink = async (body): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.sendResetPasswordLink, body);
};

export const saveNewPassword = async ({ params, body }): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.saveNewPassword, { params, body });
};

export const sendOtpCode = async (body): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.sendOtpCode, body);
};

export const confirmOtpCode = async (body): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.confirmOtpCode, body);
};

export const updateAccountDetails = async (body): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.updateAccountDetails, body);
};