import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import StorageProvider from "../framework/main/StorageProvider";
import { store } from "./store";
import { ApiResponse } from "./types";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: async (headers) => {
      const token = await StorageProvider.getItem("user-token");
      if (token) {
        headers.set("Token", token);
      }
      return headers;
    }
  }),
  tagTypes: [
    "Common",
    "Profile",
    // "Onboarding",
    // "CompanyIntel",
    "UserManagement"
    // "TacticalOutreach",
    // "MyLibrary"
  ],
  endpoints: () => ({})
});

export const apiCall = async (
  endpoint: any,
  params: any = null,
  options: any = {}
): Promise<any> => {
  let result = null;

  if ("useQuery" in endpoint) {
    result = await store.dispatch(
      endpoint.initiate(params, { ...options, forceRefetch: true })
    );
  } else {
    result = await store.dispatch(endpoint.initiate(params, options));
  }

  const response: ApiResponse = {
    data: result.data
  };

  if ("error" in result) {
    response.error = result.error;
  }

  return response;
};
