import React from "react";
import _ from 'lodash';
import {
  Grid,
  Button,
  Container,
  Backdrop,
  CircularProgress,
  Box,
  IconButton
} from "@material-ui/core";
import { Prompt } from 'react-router'
import AboutTheCompanyController, {
  initialValues,
  Props
} from "./AboutTheCompanyController";
import CompanyProgressHeaderWeb from "../../../components/main/CompanyProgressHeader.web";
import MustHave from "../../../components/main/MustHave";
import ProblemStatement from "./ProblemStatement.web";
import PrimaryQuestion from "./PrimaryQuestion.web";
import PrimaryQuestionWithFormik from "./PrimaryQuestionWithFormik.web";
import MissionStatement from "./MissionStatement.web";
import UniqueSelling from "./UniqueSelling.web";
import { accordup, accordupGreen, accordupYellow } from "./assets";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Form, Formik } from "formik";
import { aboutTheCompanySchema } from "../utils/schema";
import { modalInfoContent } from "../utils/data";
import ClearAllInformationDialog from "./ClearAllInformationDialog.web";
import SkipOnboardingConfirmationDialog from "../../../components/main/SkipOnboardingConfirmationDialog";
import { enqueueSnackbar } from "notistack";
import { getVisibleElementsByClassName } from "../../../helpers/other";
import { ReactComponent as BackButtonIcon } from '../../../assets/icons/back_button.svg';
import OnboardingHelperTooltip from "../../../components/main/OnboardingHelperTooltip";
import UploadDocumentsDialog from "./UploadDocumentsDialog.web";

export default class AboutTheCompany extends AboutTheCompanyController {
  handleSearchDebounced: any;
  formRef: any;

  constructor(props: Props) {
    super(props);

    this.formRef = React.createRef();
    this.handleSearchDebounced = _.debounce(this.autoSave, 1500);
  }

  async componentDidMount() {
    super.componentDidMount();

    this.setState({
      setAutoSaveData: this.props.setAutoSaveData,
      formRef: this.formRef.current
    })

    this.props.setFormRef(this.formRef.current);

    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  async componentWillUnmount() {
    super.componentWillUnmount();

    this.autoSave();

    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };

  autoSave = () => {
    // this.formRef.current.validateForm();

    // if (this.formRef && 'current' in this.formRef) {
    //   this.props.setAutoSaveData('company', this.formRef.current?.values);

    //   if (!Object.keys(this.formRef.current?.errors)?.length && this.formRef.current?.dirty) {
    //     this.saveAndContinue(this.formRef.current.values, true, true);
    //   }
    // }
  };

  handleScrollToError = () => {
    setTimeout(() => {
      const elements = getVisibleElementsByClassName('Mui-error');

      if (elements.length) {
        elements[0].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
      }
    }, 500);
  }

  render() {
    const { alreadyFilledFormValues } = this.state;
    const haveInitData = Object.keys(alreadyFilledFormValues).length > 0;
    const formikIntialValues = this.props.autoSavedValues?.company
      ? this.props.autoSavedValues.company
      : haveInitData
        ? alreadyFilledFormValues
        : initialValues;

    return (
      <>
        <Backdrop style={webStyle.backdrop} open={this.state.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        {!this.props.hideBackBtn && (
          <IconButton
            size="small"
            style={{
              position: 'absolute',
              left: '45px',
              top: '100px',
            }}
            onClick={() => {
              this.setState({
                skipModalOpen: true
              })
            }}
          >
            <BackButtonIcon />
          </IconButton>
        )}

        <Grid container style={webStyle.servicesMainContainer}>
          <>
            <CompanyProgressHeaderWeb
              onConfirm={this.fetchDataFromAPI}
              primaryHeading={this.state.headerPrimaryHeading}
              secondaryHeading={this.state.headerSecondaryHeading}
              tooltips={this.props.tooltips}
              closeTooltip={this.props.closeTooltip}
            />
            <Grid item style={webStyle.displayCol}>
              <Formik
                innerRef={this.formRef as any}
                initialValues={formikIntialValues}
                validationSchema={aboutTheCompanySchema}
                enableReinitialize
                isInitialValid={false}
                onSubmit={(values: any) => {
                  this.saveAndContinue(values, false, true);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  // handleSubmit,
                  setFieldValue,
                  resetForm
                }) => {
                  return (
                    <Form
                      translate={undefined}
                      onKeyDown={this.handleSearchDebounced}
                    >
                      <MustHave
                        data-test-id={"mustHaveTestId"}
                        btnDisplay={false}
                        initData={haveInitData}
                        customlistTitle={
                          "Why do we exist? (semantic keywords)"
                        }
                        addItem={this.addChipLabels}
                        handleChange={this.handleChange}
                        inputList={[]}
                        deleteChipByName={this.deleteChipByName}
                        textBoxDisplay={false}
                        iconName={accordup}
                        headerName={"MUST HAVE"}
                        labelsDisplay={true}
                        formikValue={values}
                        serviceArray={this.state.serviceArray1}
                        mustHaveExpanded={this.state.mustHaveExpanded}
                        handleExpand={this.handleExpand}
                        chipLabels={values.whyExist}
                        onChangeChip={(updated: string[]) => {
                          setFieldValue("whyExist", updated);
                          this.handleSearchDebounced();
                        }}
                        name={"whyExist"}
                        chipError={this.checkValidation(
                          !!touched.whyExist,
                          errors.whyExist as string
                        )}
                        tooltips={this.props.tooltips}
                        closeTooltip={this.props.closeTooltip}
                        section={'company'}
                      />
                      {this.state.mustHaveExpanded && (
                        <>
                          <ProblemStatement
                            data-test-id={"problemStatementTestId"}
                            handleChange1={(event: any, index: number) => {
                              setFieldValue(
                                `description${index + 1}`,
                                event.target.value
                              );
                              this.handleCommonObjectChange(
                                event,
                                index,
                                "offeringsInputList",
                                "description"
                              );
                            }}
                            navigation={this.props.navigation}
                            id=""
                            title={"Problem Statement(s)*"}
                            inputList={this.state.offeringsInputList.map(
                              (item, index) => {
                                return {
                                  ...item,
                                  offering: values[`offering${index + 1}`],
                                  description:
                                    values[`description${index + 1}`],
                                  offeringError: this.checkValidation(
                                    !!touched[`offering${index + 1}`],
                                    errors[`offering${index + 1}`]
                                  ),
                                  descriptionError: this.checkValidation(
                                    !!touched[`description${index + 1}`],
                                    errors[`description${index + 1}`]
                                  )
                                };
                              }
                            )}
                            handleChange={(event: any, index: number) => {
                              setFieldValue(
                                `offering${index + 1}`,
                                event.target.value
                              );
                              this.handleCommonObjectChange(
                                event,
                                index,
                                "offeringsInputList",
                                "offering"
                              );
                            }}
                            serviceArray={this.state.offeringsServiceArray}
                            name1={"offering"}
                            name2={"description"}
                            tooltipData={modalInfoContent.problemStatement}
                            requiredIndex={0}
                          />

                          <PrimaryQuestion
                            handleChange={(event: any, index: number) => {
                              setFieldValue(
                                `primaryFearQuestion${index + 1}`,
                                event.target.value
                              );
                              this.handleCommonObjectChange(
                                event,
                                index,
                                "primaryQuestionInputList",
                                "value"
                              );
                            }}
                            // handleChange={handleChange('primaryFearQuestion')}
                            navigation={this.props.navigation}
                            id=""
                            title={"Primary Fear Question(s)*"}
                            inputList={this.state.primaryQuestionInputList.map(
                              (input: any, index: number) => {
                                return {
                                  ...input,
                                  value:
                                    values[`primaryFearQuestion${index + 1}`],
                                  error: this.checkValidation(
                                    !!touched[
                                    `primaryFearQuestion${index + 1}`
                                    ],
                                    errors[`primaryFearQuestion${index + 1}`]
                                  )
                                };
                              }
                            )}
                            serviceArray={
                              this.state.primaryQuestionServiceArr
                            }
                            btnDisplay={false}
                            buttonName={""}
                            tooltipData={modalInfoContent.primaryFearQuestion}
                            requiredIndex={0}
                          />

                          <MissionStatement
                            handleChange={handleChange("missionStatement")}
                            navigation={this.props.navigation}
                            id=""
                            textValue={this.state.missionStatementValue}
                            values={values.missionStatement}
                            errors={this.checkValidation(
                              !!touched.missionStatement,
                              errors.missionStatement
                            )}
                            touched={touched.missionStatement}
                            name={"missionStatement"}
                            requiredIndex={0}
                            maxWords={150}
                          />

                          <UniqueSelling
                            navigation={this.props.navigation}
                            id=""
                            handleChange={handleChange("usp")}
                            textValue={this.state.uniqueSellingValue}
                            values={values.usp}
                            errors={this.checkValidation(
                              !!touched.usp,
                              errors.usp
                            )}
                            touched={touched.usp}
                            name={"usp"}
                            requiredIndex={0}
                            maxWords={200}
                          />
                        </>
                      )}
                      <MustHave
                        data-test-id="shouldHaveTestId"
                        btnDisplay={true}
                        customlistTitle={"Company Testimonials"}
                        addItem={this.handleAddObjectItem}
                        handleChange={(event, index) =>
                          this.handleCommonObjectChange(
                            event,
                            index,
                            "inputList",
                            "value"
                          )
                        }
                        inputList={this.state.inputList}
                        textBoxDisplay={true}
                        iconName={accordupGreen}
                        headerName={"SHOULD HAVE"}
                        labelsDisplay={false}
                        serviceArray={this.state.companyTestimonialArrayData}
                        mustHaveExpanded={this.state.shouldHaveExpanded}
                        handleExpand={this.handleExpand1}
                        chipLabels={[]}
                        deleteChipByName={this.deleteChipByName}
                        name={`companyTestimonial`}
                        formikHandelChange={handleChange}
                        formikValue={values}
                        formikError={errors}
                        formikTouched={touched}
                        setFieldValue={setFieldValue}
                        maxFeilds={3}
                      />
                      {this.state.shouldHaveExpanded && (
                        <>
                          <PrimaryQuestionWithFormik
                            data-test-id={"PrimaryQuestion1"}
                            navigation={this.props.navigation}
                            id=""
                            title={"Company Achievements"}
                            inputList={
                              this.state.companyAchievementsInputList
                            }
                            serviceArray={
                              this.state.companyAchievementsServiceArr
                            }
                            btnDisplay={true}
                            buttonName={"Add an achievement"}
                            name={`companyAchivements`}
                            formikHandelChange={handleChange}
                            formikValue={values}
                            formikError={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            maxFeilds={3}
                          />

                          <PrimaryQuestionWithFormik
                            data-test-id={"PrimaryQuestion2"}
                            navigation={this.props.navigation}
                            id=""
                            title={"Key Company Facts"}
                            inputList={this.state.companyFactsInputList}
                            serviceArray={this.state.companyFactsServiceArr}
                            btnDisplay={true}
                            name={`keyCompanyFacts`}
                            formikHandelChange={handleChange}
                            formikValue={values}
                            formikError={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            buttonName={"Add a fact"}
                            maxFeilds={5}
                          />

                          <PrimaryQuestionWithFormik
                            data-test-id={"PrimaryQuestion3"}
                            navigation={this.props.navigation}
                            id=""
                            title={"Company Evolution Timeline"}
                            inputList={this.state.companyEvaluationInputList}
                            serviceArray={
                              this.state.companyEvaluationServiceArr
                            }
                            btnDisplay={false}
                            buttonName={""}
                            name={`companyEvalutionTime`}
                            formikHandelChange={handleChange}
                            formikValue={values}
                            formikError={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                          />

                          <PrimaryQuestionWithFormik
                            data-test-id={"PrimaryQuestion4"}
                            navigation={this.props.navigation}
                            id=""
                            title={"Company Headquarters"}
                            inputList={this.state.companyHeadquarterInputList}
                            serviceArray={
                              this.state.companyHeadQuarterServiceArr
                            }
                            btnDisplay={false}
                            buttonName={""}
                            name={`companyHeadquarters`}
                            formikHandelChange={handleChange}
                            formikValue={values}
                            formikError={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                          />
                        </>
                      )}
                      <MustHave
                        data-test-id="goodToHaveTestId"
                        btnDisplay={false}
                        customlistTitle={"Vision Statement"}
                        handleChange={(event: any, index: number) =>
                          this.handleCommonObjectChange(
                            event,
                            index,
                            "vissionStateMentInputList",
                            "value"
                          )
                        }
                        inputList={this.state.vissionStateMentInputList}
                        textBoxDisplay={true}
                        iconName={accordupYellow}
                        headerName={"GOOD TO HAVE"}
                        labelsDisplay={false}
                        serviceArray={this.state.companyHeadQuarterServiceArr}
                        mustHaveExpanded={this.state.goodToHaveExpanded}
                        handleExpand={this.handleExpand2}
                        chipLabels={[]}
                        deleteChipByName={this.deleteChipByName}
                        name={`visionStatement`}
                        formikHandelChange={handleChange}
                        formikValue={values}
                        formikError={errors}
                        formikTouched={touched}
                        setFieldValue={setFieldValue}
                      />
                      {this.state.goodToHaveExpanded && (
                        <>
                          <ProblemStatement
                            data-test-id={"ProblemStatement"}
                            handleChange={(event: any, index: number) => {
                              setFieldValue(
                                `companyValue${index + 1}`,
                                event.target.value
                              );
                              this.handleCommonObjectChange(
                                event,
                                index,
                                "companyValueInputList",
                                "offering"
                              );
                            }}
                            handleChange1={(event: any, index: number) => {
                              setFieldValue(
                                `companyDescription${index + 1}`,
                                event.target.value
                              );
                              this.handleCommonObjectChange(
                                event,
                                index,
                                "companyValueInputList",
                                "description"
                              );
                            }}
                            navigation={this.props.navigation}
                            id=""
                            title={"Company Values"}
                            serviceArray={this.state.companyValueServiceArray}
                            name1={"companyValue"}
                            name2={"companyDescription"}
                            inputList={this.state.companyValueInputList.map(
                              (item: any, index: any) => {
                                return {
                                  ...item,
                                  offering:
                                    values[`companyValue${index + 1}`],
                                  description:
                                    values[`companyDescription${index + 1}`],
                                  offeringError: this.checkValidation(
                                    !!touched[`companyValue${index + 1}`],
                                    errors[`companyValue${index + 1}`]
                                  ),
                                  descriptionError: this.checkValidation(
                                    !!touched[
                                    `companyDescription${index + 1}`
                                    ],
                                    errors[`companyDescription${index + 1}`]
                                  )
                                };
                              }
                            )}
                            tooltipData={modalInfoContent.companyValue}
                          />
                          <PrimaryQuestionWithFormik
                            data-test-id={"PrimaryQuestion5"}
                            navigation={this.props.navigation}
                            id=""
                            title={"Primary Utility Hook"}
                            inputList={this.state.primaryUtilityInputList}
                            serviceArray={this.state.primaryUtilityServiceArr}
                            btnDisplay={false}
                            buttonName={""}
                            name={`primaryUtilityHook`}
                            formikHandelChange={handleChange}
                            formikValue={values}
                            formikError={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                          />

                          <PrimaryQuestionWithFormik
                            data-test-id={"PrimaryQuestion6"}
                            navigation={this.props.navigation}
                            id=""
                            title={"MQL Statement"}
                            inputList={this.state.mqlStatementInputList}
                            serviceArray={this.state.mqlServiceArr}
                            btnDisplay={false}
                            buttonName={""}
                            name={`mqlStatement`}
                            formikHandelChange={handleChange}
                            formikValue={values}
                            formikError={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                          />

                          <PrimaryQuestionWithFormik
                            data-test-id={"PrimaryQuestion7"}
                            navigation={this.props.navigation}
                            id=""
                            title={"Contribution to Society"}
                            inputList={this.state.contributionInputList}
                            serviceArray={this.state.mqlServiceArr}
                            btnDisplay={false}
                            buttonName={""}
                            name={`contributeToSocity`}
                            formikHandelChange={handleChange}
                            formikValue={values}
                            formikError={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                          />
                        </>
                      )}
                      <Grid item style={webStyle.displayRow}>
                        <Button
                          variant="contained"
                          style={webStyle.clrBtn}
                          onClick={this.openSkipConfirmationDialog}
                        >
                          Skip onboarding
                        </Button>
                        <Button
                          variant="contained"
                          style={webStyle.clrBtn}
                          onClick={this.openClearAllInfoDilog}
                        >
                          Clear all data
                        </Button>
                        <OnboardingHelperTooltip
                          open={this.props.tooltips.company.save}
                          title={'Helper'}
                          description={
                            <>Once you've entered your company data, <b style={{ color: '#57BE99' }}>save and continue</b> to the next page.</>
                          }
                          placement='right'
                          onClose={() => this.props.closeTooltip('company', 'save')}
                        >
                          <Button
                            variant="contained"
                            style={webStyle.saveBtn}
                            type="submit"
                            onClick={() => {
                              console.log('errors', errors);

                              this.handleScrollToError();

                              if (Object.keys(errors).length) {
                                enqueueSnackbar(
                                  'Some fields are filled in incorrectly. Check the form and correct the error',
                                  { variant: 'error' }
                                )
                              }
                            }}
                          >
                            Save & continue
                          </Button>
                        </OnboardingHelperTooltip>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
          </>
        </Grid>

        <SkipOnboardingConfirmationDialog
          open={this.state.skipModalOpen}
          onClose={this.closeSkipConfirmationDialog}
          onYes={() => {
            this.saveAndContinue(this.formRef.current.values, true, true);
            this.closeSkipConfirmationDialog();
            this.redirectToPage('/home?afterSkipOnboarding=true');
          }}
          onNo={() => {
            this.closeSkipConfirmationDialog();
            this.redirectToPage('/home?afterSkipOnboarding=true');
          }}
        />

        <ClearAllInformationDialog
          open={this.state.clearAllInfoDialog}
          onClose={this.closeClearAllInfoDilog}
          onClear={() => {
            this.formRef.current.resetForm();
            this.clearAllInformation({ ...initialValues, whyExist: [] });
            this.props.setAutoSaveData('company', null);
            this.closeClearAllInfoDilog();
          }}
          onCancel={this.closeClearAllInfoDilog}
        />

        <UploadDocumentsDialog
          open={this.state.showUploadDocumentsModal}
          onClose={() => {
            this.setState({
              showUploadDocumentsModal: false
            })
          }}
          triggerAutoFill={this.fetchDataFromAPI}
        />
      </>
    );
  }
}

const webStyle = {
  clrBtn: {
    height: '44px',
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    background: "#FFF",
    width: "50%",
    textTransform: "none",
    fontWeight: 600
  } as CSSProperties,
  saveBtn: {
    height: '44px',
    borderRadius: "8px",
    background: "#57BE99",
    width: "50%",
    textTransform: "none",
    fontWeight: 600,
    boxSizing: 'border-box',
    "&:hover": {
      backgroundColor: "#000000"
    }
  } as CSSProperties,
  servicesMainContainer: {
    width: "870px",
    padding: "0 0 80px",
    margin: "0 auto",
  },
  backdrop: {
    zIndex: 9999999999999,
    color: "#fff"
  },
  displayRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
    marginTop: '20px',
  },
  displayCol: {
    width: '100%',
    display: "flex",
    flexDirection: "column",
    gap: "10px"
  } as CSSProperties,
  servicesContainer: {
    background: "#fff",
    borderRadius: "28px",
    padding: "32px 40px",
    height: "max-content"
  },
  offeringBox: {
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap" as "wrap",
    marginBottom: "2%"
  } as any
};
