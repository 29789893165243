import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Avatar, Backdrop, Box, Button, Checkbox, CircularProgress, Container, FormControl, IconButton, InputAdornment, ListItemText, MenuItem, Modal, OutlinedInput, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from '@material-ui/core';
import { AvatarGroup, Pagination } from '@material-ui/lab';
import { ReactComponent as RightBarIcon } from '../../assets/icons/right_bar_icon.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as AddUserIcon } from '../../assets/icons/plus_user.svg';
import { ReactComponent as NoticationIcon } from '../../assets/icons/notification.svg';
import { ReactComponent as ChatIcon } from '../../assets/icons/chat.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as ArrowDropDownIcon } from '../../assets/icons/dropdown_down.svg';
import { ReactComponent as SortInactiveIcon } from '../../assets/icons/sort_inactive.svg';
import CheckBoxIcon from '../../assets/icons/checkbox.svg';
import CheckBoxCheckedIcon from '../../assets/icons/checkbox_checked.svg';
import InviteUserModal from './components/InviteUserModal';
import { PERMISSIONS } from '../../utils/enums';
import ConfirmationModal from '../../components/main/ConfirmationModal';
import { deleteAccountUser, inviteAccountUser, updateAccountUser, useGetAccountUsersQuery } from '../../redux/services/user-management';

import { useStyles } from './styles';
import { enqueueSnackbar } from 'notistack';
import { useGetProfileQuery } from '../../redux/services/profile';
import { lastActivityDateFormat } from '../../helpers/date';
import TextField from '../../components/main/CustomFields/TextField';
import { useHistory } from 'react-router-dom';

interface Props {
  navigation: any;
}

const UserManagement: React.FC<Props> = (props) => {
  const [users, setUsers] = useState<any[] | null>();
  const [userToDelete, setUserToDelete] = useState<any>(null);
  const [showInviteUserModal, setShowInviteUserModal] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sortConfig, setSortConfig] = useState<{ key: string, direction: string } | null>(null);
  const [paginationConfig, setPaginationConfig] = useState<{ page: number, count: number }>({
    page: 1,
    count: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();
  const classes = useStyles();
  const { data: profile } = useGetProfileQuery();
  const { data: accountUsers, error, isLoading, refetch } = useGetAccountUsersQuery({
    search,
    page: paginationConfig.page,
    sortBy: sortConfig?.key,
    sortDirection: sortConfig?.direction,
  });

  useEffect(() => {
    if (profile && !error && !isLoading && accountUsers?.data && Array.isArray(accountUsers.data)) {
      const { data, meta } = accountUsers;

      const users = data.map(item => (
        {
          id: item.id,
          avatar: item.attributes.profile_photo,
          name: item.attributes.name || '-',
          role: item.attributes.job_role,
          email: item.attributes.email,
          laftActivity: lastActivityDateFormat(item.attributes.last_activity_at),
          permissions: [
            'generate intel',
            'review intel',
            'change onboarding',
          ]
        }
      )).filter(item => item.email !== profile.attributes.account.email)

      setUsers(users);

      setPaginationConfig({
        ...paginationConfig,
        count: Math.ceil((search.length ? users.length : meta.total) / 10)
      });
    }
  }, [profile, accountUsers, error, isLoading, search]);

  const debouncedSearch = useCallback(
    _.debounce((term) => {
      setSearch(term);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(searchTerm);

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTerm, debouncedSearch]);

  const setPermission = async (id: number, permission: string) => {
    const userToUpdate = users.find(item => item.id === id);

    const permissions = userToUpdate.permissions.includes(permission)
      ? userToUpdate.permissions.filter(item => item !== permission)
      : [
        ...userToUpdate.permissions,
        permission
      ]

    setUsers(
      users.map(item => {
        return item.id === id.toString()
          ? {
            ...item,
            permissions
          }
          : item
      })
    )

    enqueueSnackbar(
      `User permissions changed successfully`,
      { variant: 'success' }
    );

    // const { data } = await updateAccountUser({ id, body: { permissions } });

    // if (data) {
    //   enqueueSnackbar(
    //     `User permission changed successfully`,
    //     { variant: 'success' }
    //   );
    // } else {
    //   enqueueSnackbar(
    //     `User permission change failed`,
    //     { variant: 'error' }
    //   );
    // }
  }

  const deleteUser = async () => {
    const { id } = userToDelete;

    setLoading(true);
    setUserToDelete(null);

    const response = await deleteAccountUser(id);

    if ('data' in response) {
      enqueueSnackbar(
        `User deleted successfully`,
        { variant: 'success' }
      );
    } else {
      enqueueSnackbar(
        `User deletion failed`,
        { variant: 'error' }
      );
    }

    setLoading(false);
  }

  const addUser = (values) => {
    setShowInviteUserModal(false);
    setLoading(true);

    const promises = values.emails.map(async item => {
      return await inviteAccountUser({
        email: item,
        permissions: values.permissions,
        message: values.message
      });
    })

    Promise.allSettled(promises).then(res => {
      const erroneous = res.filter((item: any) => 'error' in item.value);
      const successful = res.filter((item: any) => 'data' in item.value && !('error' in item.value));

      if (successful.length) {
        enqueueSnackbar(
          `${successful.length} user(s) invited successfully`,
          { variant: 'success' }
        );
      }

      if (erroneous.length) {
        enqueueSnackbar(
          `${erroneous.length} user(s) failed in the invite process`,
          { variant: 'error' }
        );
      }

      setLoading(false);
    })
  }

  const searchUser = (event) => {
    if (event.target.value.length) {
      setPaginationConfig({
        ...paginationConfig,
        page: 1
      });
    }

    setSearchTerm(event.target.value);
  }

  const handleSort = (key: string) => {
    if (users?.length) {
      let direction = 'asc';

      if (sortConfig && sortConfig.key === key) {
        if (sortConfig.direction === 'asc') {
          direction = 'desc';
        } else {
          direction = null;
        }
      }

      if (direction) {
        setSortConfig({ key, direction });
      } else {
        setSortConfig(null);
      }
    }
  }

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 99999 }}
        open={isLoading || loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container
        style={{
          maxWidth: '1280px',
          minWidth: '880px',
          padding: '80px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          rowGap: '20px',
        }}
      >
        {/* <Box
          style={{
            position: 'absolute',
            top: '20px',
            right: '40px',
            display: 'flex',
            alignItems: 'center',
            // columnGap: '16px'
          }}
        >
          // https://mui.com/material-ui/react-menu/
          // use this for show list of online users
          <AvatarGroup
            max={4}
            style={{
              paddingRight: '16px',
              zoom: '.8'
            }}
          >
            {[1, 2, 3, 4, 5, 6, 7].map((item, index) => (
              <Avatar
                src="/static/images/avatar/1.jpg"
                alt="Remy Sharp"
                style={{
                  // width: '28px',
                  // height: '28px',
                  zIndex: index - [1, 2, 3, 4, 5, 6, 7].length - 1,
                  marginLeft: index !== 0 ? '-20px' : '0px'
                }}
              />
            ))}
          </AvatarGroup>
          <IconButton
            onClick={handleRightMenu}
          >
            <NoticationIcon />
          </IconButton>
          <IconButton
            onClick={handleRightMenu}
          >
            <ChatIcon />
          </IconButton>
        </Box> */}
        <Typography
          style={{
            fontSize: '48px',
            fontWeight: '600',
            color: '#000000',
            lineHeight: '58px'
          }}
        >
          User management
        </Typography>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '20px'
          }}
        >
          <Box style={{ width: '271px' }}>
            <TextField
              type={'search'}
              placeholder={'Search users'}
              value={searchTerm}
              onChange={searchUser}
              onSearchPress={refetch}
              onKeyPress={refetch}
            />
          </Box>
          <Button
            variant="contained"
            startIcon={<AddUserIcon />}
            onClick={() => setShowInviteUserModal(true)}
            style={{
              minWidth: '130px',
              minHeight: '44px',
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '19px',
              color: '#FFFFFF',
              padding: '12px 14px',
              borderRadius: '8px',
              textTransform: 'none',
              boxShadow: 'none',
              background: '#000000',
            }}
          >
            Add user
          </Button>
        </Box>
        <TableContainer
          component={Paper}
          style={{
            width: '100%',
            boxShadow: 'none'
          }}
        >
          <Table>
            {/* <TableHead>
              <TableRow>
                <TableCell align="center" />
                <TableCell
                  align="left"
                  onClick={() => handleSort('name')}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <TableSortLabel
                    active={sortConfig?.key === 'name'}
                    direction={sortConfig?.direction === 'asc' ? 'asc' : 'desc'}
                    style={{
                      width: '100%',
                      height: '100%'
                    }}
                  >
                    User
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  onClick={() => handleSort('email')}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <TableSortLabel
                    active={sortConfig?.key === 'email'}
                    direction={sortConfig?.direction === 'asc' ? 'asc' : 'desc'}
                    style={{
                      width: '100%',
                      height: '100%'
                    }}
                  >
                    Email
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">Last activity</TableCell>
                <TableCell align="left">Permissions</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead> */}
            <TableBody>
              {users?.map((row, index) => (
                <TableRow key={row.name + row.role + row.email + row.avatar + index}>
                  <TableCell align="center">
                    <Avatar
                      src={row.avatar}
                      alt={row.name}
                      style={{ width: '40px', height: '40px', margin: '0 auto' }}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <Typography style={{ fontSize: '16px', fontWeight: '600', lineHeight: '19px', marginBottom: '2px' }}>
                      {row.name}
                    </Typography>
                    {row.role && (
                      <Typography style={{ fontSize: '12px', fontWeight: '400', lineHeight: '14px', color: '#475569' }}>
                        {row.role}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    <Typography style={{ fontSize: '14px', fontWeight: '500', lineHeight: '14px', color: '#475569' }}>
                      {row.email}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography style={{ fontSize: '12px', fontWeight: '400', lineHeight: '12px', color: '#475569' }}>
                      {row.laftActivity}
                    </Typography>
                  </TableCell>
                  {/* <TableCell align="left">
                    <Select
                      multiple
                      displayEmpty
                      variant={"filled"}
                      value={row.permissions}
                      className={classes.select}
                      IconComponent={ArrowDropDownIcon}
                      MenuProps={{
                        className: classes.menu,
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                      }}
                      renderValue={(selected: string[]) => {
                        if (selected.length) {
                          return selected.map((item, index) => (
                            <Typography style={{ fontSize: '12px', fontWeight: '500', lineHeight: '14px' }}>
                              {item}{index !== selected.length - 1 ? ',' : ''}
                            </Typography>
                          ))
                        } else {
                          return (
                            <Typography style={{ fontSize: '12px', fontWeight: '500', lineHeight: '14px' }}>
                              No permissions provided
                            </Typography>
                          )
                        }
                      }}
                    >
                      {Object.values(PERMISSIONS).map((item, index) => (
                        <MenuItem
                          key={item + index}
                          value={item}
                          className={classes.item}
                          onClick={() => setPermission(row.id, item)}
                        >
                          <Checkbox
                            icon={<img src={CheckBoxIcon} />}
                            checkedIcon={<img src={CheckBoxCheckedIcon} />}
                            checked={row.permissions.includes(item)}
                          />
                          <ListItemText
                            primary={item}
                            style={{
                              margin: '0',
                              textTransform: 'lowercase'
                            }}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell> */}
                  <TableCell align="right">
                    <Button
                      color='secondary'
                      variant='outlined'
                      onClick={() => history.push(`/user/${row.id}`)}
                    >
                      View
                    </Button>
                    &nbsp;
                    &nbsp;
                    <Button
                      color='secondary'
                      variant='outlined'
                    >
                      Deactivate
                    </Button>
                    {/* <IconButton
                      onClick={() => setUserToDelete(row)}
                    >
                      <DeleteIcon />
                    </IconButton> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {!isLoading && !users?.length && (
            <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '80px 0px' }}>
              <Typography>
                No users
              </Typography>
            </Box>
          )}
          {paginationConfig.count > 1 && (
            <Box
              style={{
                width: '100%',
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Pagination
                count={paginationConfig.count}
                page={paginationConfig.page}
                hideNextButton={!paginationConfig.count}
                hidePrevButton={!paginationConfig.count}
                onChange={(event: React.ChangeEvent, page: number) => setPaginationConfig({ count: paginationConfig.count, page })}
              />
            </Box>
          )}
        </TableContainer>
      </Container>

      <ConfirmationModal
        open={!!userToDelete}
        title={'Are you sure you want to delete user from the plarform?'}
        description={'Note that all data he or she saved and generated will be deleted'}
        rejectBtnText={'Close'}
        confirmBtnText={'Delete user'}
        onClose={() => setUserToDelete(null)}
        onReject={() => setUserToDelete(null)}
        onConfirm={() => deleteUser()}
      />

      <InviteUserModal
        open={showInviteUserModal}
        onClose={() => setShowInviteUserModal(false)}
        onInvite={addUser}
      />
    </>
  );
};

export default UserManagement;
