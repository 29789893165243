import React, { useState } from 'react';
import { Backdrop, Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { ReactComponent as ThreeReferralsIcon } from '../../assets/icons/3_referrals.svg';
import { ReactComponent as MassReferralIcon } from '../../assets/icons/mass_referal.svg';
import { ReactComponent as LinkIcon } from '../../assets/icons/link.svg';
import ReferralsGiftRobotIcon from '../../assets/images/referrals_gift_robot.png';

import { useStyles } from './styles';
import { enqueueSnackbar } from 'notistack';

const ShareAndEarn = () => {
  const [options, setOptions] = useState<any>([
    {
      icon: <ThreeReferralsIcon />,
      color: '#3A84C9',
      titleColor: '#3A84C9',
      title: '3 friends',
      description: <>
        Successfully refer <b>3 friends</b>, who sign up beyond the 14-day trial, to receive <b>3 months</b> of GTM Professional <b>free</b>.
      </>,
      amount: {
        required: 3,
        current: 0,
      },
    },
    {
      icon: <MassReferralIcon />,
      color: '#CAB354',
      titleColor: '#000000',
      title: 'Mass Referal',
      description: <>
        Successfully refer <b>10 friends</b>, who sign up beyond the 14-day trial, to receive <b>1 year</b> of GTM Professional <b>free</b>.
      </>,
      amount: {
        required: 3,
        current: 0,
      },
    },
  ]);
  const [loading, setLoading] = useState<boolean>(false);

  const classes = useStyles();

  const handleCopyLink = () => {
    setLoading(true);

    const code = 'bla-bla-bla';

    navigator.clipboard.writeText(`${window.location.origin}/signup?ref=${code}`)
      .then(() => {
        enqueueSnackbar(
          'Referral link copied to clipborad',
          { variant: 'info' }
        )
      })
      .catch(err => {
        enqueueSnackbar(
          'Failed to copy',
          { variant: 'error' }
        )
      });

    setLoading(false);
  }

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 9999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.container}>
        <Typography className={classes.title}>
          Share & Earn
        </Typography>
        <Box className={classes.referralBoxContainer}>
          {options.map((item, index) => (
            <Box 
              key={index}
              className={classes.referralBox}
              style={{
                borderColor: item.color
              }}
            >
              <Box className={classes.referralHeader}>
                {item.icon}
                <Typography className={classes.referralProgress}>
                  <Typography 
                    className={classes.referralCount}
                    style={{
                      color: item.color
                    }}
                  >
                    {item.amount.current}&nbsp;
                  </Typography>
                  / {item.amount.required} referrals
                </Typography>
              </Box>
              <Box className={classes.referralDetails}>
                <Typography 
                  className={classes.referralFriends}
                  style={{
                    color: item.titleColor
                  }}
                >
                  {item.title}
                </Typography>
                <Typography className={classes.referralText}>
                  {item.description}
                </Typography>
              </Box>
              <Button
                variant='outlined'
                color='secondary'
                startIcon={<LinkIcon style={{ color: item.color }} />}
                onClick={() => handleCopyLink()}
              >
                Get a referral link
              </Button>
            </Box>
          ))}
        </Box>

        <Box className={classes.additionalOffersContainer}>
          <Box className={classes.inviteFriendsBox}>
            <Box className={classes.inviteFriendsContent}>
              <Typography className={classes.inviteTitle}>
                INVITE FRIENDS & EARN REWARDS
              </Typography>
              <Typography className={classes.inviteText}>
                Invite 10 friends to join the platform and get a big present.
              </Typography>
            </Box>
            <img height={'200px'} src={ReferralsGiftRobotIcon} alt='Gift' />
          </Box>

          <Box className={classes.otherOffersBox}>
            <Typography className={classes.otherOffersText}>
              OTHER OFFERS COMING SOON
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ShareAndEarn;
