import React, { useEffect, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import { Backdrop, Box, Button, CircularProgress, FormControlLabel, IconButton, Switch, Typography } from "@material-ui/core";
import { ReactComponent as EditIcon } from '../../assets/icons/edit_dark.svg';
import { ReactComponent as LibraryBooksIcon } from '../../assets/icons/library_books.svg';
import { ReactComponent as DuplicateIcon } from '../../assets/icons/duplicate_icon.svg';
import { ReactComponent as ReloadIcon } from '../../assets/icons/reload_icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete_red.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar_icon.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/clock_icon.svg';
import { ReactComponent as BlueCheckmarkIcon } from '../../assets/icons/blue_checkmark.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as SideBarOpenIcon } from '../../assets/icons/side_bar_open_icon.svg';
import TextField from "../../components/main/CustomFields/TextField";
import { getUrlAndParams, removeUrlParams } from "../../helpers/other";
import { getCampaignById, getCampaignEmails, triggerEmailGenerate, updateCampaign, updateCampaignEmail } from "../../redux/services/tactical-outreach";
import ConfirmationModal from "../../components/main/ConfirmationModal";
import { enqueueSnackbar } from "notistack";
import { trimTextByCharacters } from "../../helpers/text";
import SideBar from "./components/SideBar";
import ContentEditor from "./components/ContentEditor";
import { convertHTMLToState, convertMarkdownToHtml, convertStateToHTML, isMarkdown } from "./helpers";
import LimitationWidget from "../../components/main/LimitationWidget";
import { observableService } from "../../services/observable";

import { useStyles } from "./styles";

const TacticalOutreachEmail = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [campaign, setCampaign] = useState<any>(null);
  const [email, setEmail] = useState<any>(null);
  const [unsavedDataModal, setUnsavedDataModal] = useState<{ active: boolean, targetEmail: number }>({
    active: false,
    targetEmail: null,
  });
  const [editSubject, setEditSubject] = useState<{ value: string, field: string } | null>(null);
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(true);
  const [leavingModal, setLeavingModal] = useState<{ active: boolean, nextLocation: string | null }>({
    active: false,
    nextLocation: null
  });
  const [editorState, setEditorState] = useState<any>(null);
  const [showGenerationFailed, setShowGenerationFailed] = useState<boolean>(false);
  const [showStopGeneration, setShowStopGeneration] = useState<boolean>(false);
  const [emailGeneratingProcessActive, setEmailGeneratingProcessActive] = useState<boolean>(false);

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (email) {
      const { params } = getUrlAndParams();
      params.set('email_id', email.id);

      window.history.replaceState(null, '', `${window.location.pathname}?${params.toString()}`);
    }
  }, [email]);

  useEffect(() => {
    if (email?.content?.length) {
      let content = email.content;
      
      if (isMarkdown(content)) {
        content = convertMarkdownToHtml(content)
      }

      setEditorState(
        convertHTMLToState(content)
      );
    } else {
      setEditorState(
        convertHTMLToState('')
      );
    }
  }, [email?.content]);

  useEffect(() => {
    setLoading(true);

    const { params } = getUrlAndParams();
    const campaign_id = params.get('campaign_id');
    const email_id = params.get('email_id');

    if (!campaign_id || campaign_id === 'null') {
      enqueueSnackbar(
        'Campaign not found',
        { variant: 'error' }
      )

      history.push('/tactical-outreach');
    } else {
      handleGetCampaign(+campaign_id, +email_id);
    }

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };

  const handleGetCampaign = async (campaignId, emailId) => {
    const { data: campaign, error: campaignError } = await getCampaignById(campaignId);
    const { data: emails, error: emailsError } = await getCampaignEmails(campaignId);

    if (emails && campaign && !campaignError && !emailsError) {
      const newCapmaign = {
        ...campaign,
        emails: emails
          .map(item => {
            return {
              ...item,
              status: item?.content?.trim()?.length ? 'READY' : 'ERROR'
            }
          })
          .sort((a, b) => a.order - b.order)
      }
      const targetEmail = emailId
        ? emails.find(item => (item.id === emailId) || (item.id === email?.id)) || emails[emails.length - 1]
        : emails[emails.length - 1]

      setCampaign(newCapmaign)

      setEmail({
        ...targetEmail,
        status: targetEmail?.content?.trim()?.length ? 'READY' : 'ERROR'
      })

      if (campaign.subCategory === 'MULTI_TOUCH_CAMPAIGNS' && campaign.status === 'INCOMPLETED') {
        handleTriggerEmailGenerate(newCapmaign, newCapmaign.emails);
      }
    }

    setLoading(false);
  }

  const handleSaveEmail = async () => {
    const { data, error } = await updateCampaignEmail({
      body: {
        ...email,
        content: convertStateToHTML(editorState)
      },
      id: email.id
    });

    if (data && !error) {
      handleGetCampaign(data.campaignId, data.id);

      enqueueSnackbar(
        'Changes saved successfully',
        { variant: 'success' }
      )
    } else {
      enqueueSnackbar(
        'Failed to save changes',
        { variant: 'error' }
      )
    }
  }

  const handleSaveEdit = async () => {
    if (
      (editSubject.field === 'name' && editSubject.value.trim().length > 50) ||
      (editSubject.field === 'description' && editSubject.value.trim().length > 300)
    ) {
      enqueueSnackbar(
        `Max ${editSubject.field === 'name' ? '50' : '300'} characters`,
        { variant: 'error' }
      )
    } else {
      const { error } = await updateCampaign({
        body: {
          name: campaign.name,
          description: campaign.description,
          [editSubject.field]: editSubject.value
        },
        id: campaign.id
      });

      if (!error) {
        handleGetCampaign(campaign.id, email.id);

        enqueueSnackbar(
          'Campaign edited successfully',
          { variant: 'success' }
        )
      } else {
        enqueueSnackbar(
          'Failed to edit',
          { variant: 'error' }
        )
      }

      setEditSubject(null);
    }
  }

  const isSaveDisabled = () => {
    if (campaign && email) {
      if (email.status === 'ERROR') {
        return true;
      } else {
        const originalEmail = {
          subject: campaign.emails.find(item => item.id === email.id).subject,
          content: campaign.emails.find(item => item.id === email.id).content
        }

        originalEmail.content = isMarkdown(originalEmail.content)
          ? convertMarkdownToHtml(originalEmail.content)
          : originalEmail.content

        const currentEmail = {
          subject: email.subject,
          content: convertStateToHTML(editorState),
        }

        return JSON.stringify(originalEmail) === JSON.stringify(currentEmail);
      }
    } else {
      return true;
    }
  }

  const handleBlockedNavigation = (nextLocation) => {
    setLeavingModal({
      active: true,
      nextLocation: nextLocation.pathname + nextLocation.search
    })

    return false;
  };

  const handleTriggerEmailGenerate = async (campaign, emails) => {
    setEmailGeneratingProcessActive(true);

    const newCampaign = {
      ...campaign,
      emails: [
        ...emails,
        ...Array.from(Array(campaign.totalEmails - emails.length).keys()).map(() => ({
          subject: '',
          content: '',
          createdAt: '',
          updatedAt: '',
          status: 'PENDING'
        }))
      ]
    }

    setCampaign(newCampaign);
    
    const { data, error } = await triggerEmailGenerate({ campaignId: newCampaign.id, emailType: 'multi-touch' });

    if (data && !error) {
      observableService.sendEvent('Decrease usage amount');

      handleGetCampaign(newCampaign.id, email?.id);

      if (data.metaData.index >= data.metaData.total) {
        setEmailGeneratingProcessActive(false);

        observableService.sendEvent('Refetch usage amount');

        enqueueSnackbar(
          `All ${data.metaData.total} emails generated successfully`,
          { variant: 'success' }
        )
      }
    } else {
      setEmailGeneratingProcessActive(false);

      if (error?.status === 402) {
        observableService.sendEvent('Show top up modal for TACTICAL_OUTREACH');
      } else {
        setShowGenerationFailed(true);

        setCampaign({
          ...newCampaign,
          emails: [
            ...newCampaign.emails.filter(item => item.status === 'READY'),
            {
              subject: '',
              content: '',
              createdAt: '',
              updatedAt: '',
              status: 'ERROR'
            }
          ]
        });
      }
    }
  }

  return (
    <>
      <Prompt
        when={(campaign?.status === 'INCOMPLETED' || !isSaveDisabled()) && !loading}
        message={(location) => handleBlockedNavigation(location)}
      />

      <Backdrop
        style={{ color: '#fff', zIndex: 9999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.wrapper}>
        <Box className={classes.container}>
          {campaign && email && (
            <Box
              className={classes.header}
              style={{
                minHeight: editSubject ? '100px' : 'fit-content'
              }}
            >
              <Box className={classes.nameBlock}>
                {editSubject?.field === 'name'
                  ? <Box style={{ width: '80%', display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <TextField
                        value={editSubject.value}
                        placeholder={'Name'}
                        onChange={(event) => {
                          setEditSubject({ ...editSubject, value: event.target.value })
                        }}
                      />
                      <IconButton
                        size='small'
                        onClick={handleSaveEdit}
                      >
                        <BlueCheckmarkIcon />
                      </IconButton>
                    </Box>
                  : <Box style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <Typography noWrap style={{ fontSize: '16px', fontWeight: '600', lineHeight: '19px' }}>
                        {trimTextByCharacters(campaign.name, 50)}
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={() => {
                          if (campaign.status === 'INCOMPLETED') {
                            setShowStopGeneration(true);
                          } else {
                            setEditSubject({ field: 'name', value: campaign.name })
                          }
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>
                }
                {editSubject?.field === 'description'
                  ? <Box style={{ width: '80%', display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <TextField
                        value={editSubject.value}
                        placeholder={'Name'}
                        onChange={(event) => {
                          setEditSubject({ ...editSubject, value: event.target.value })
                        }}
                      />
                      <IconButton
                        size='small'
                        onClick={handleSaveEdit}
                      >
                        <BlueCheckmarkIcon />
                      </IconButton>
                    </Box>
                  : <Box style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <Typography noWrap style={{ fontSize: '12px', fontWeight: '600', lineHeight: '14px', color: '#475569' }}>
                        {trimTextByCharacters(campaign.description, 50)}
                      </Typography>
                      <IconButton
                        size='small'
                        onClick={() => {
                          if (campaign.status === 'INCOMPLETED') {
                            setShowStopGeneration(true);
                          } else {
                            setEditSubject({ field: 'description', value: campaign.description })
                          }
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>
                }
              </Box>
              {campaign.type && (
                <Typography
                  className={classes.tag}
                  style={{
                    color: campaign.category === 'MARKETING' ? '#57BE99' : campaign.category === 'SALES' ? '#D93855' : '#3A84C9',
                    borderColor: campaign.category === 'MARKETING' ? '#57BE99' : campaign.category === 'SALES' ? '#D93855' : '#3A84C9',
                  }}
                >
                  {campaign.type.toLowerCase()?.replace(/_/g, ' ')?.replace(/\b\w/g, char => char.toUpperCase())}
                </Typography>
              )}
              <Box style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<EditIcon />}
                  style={{
                    whiteSpace: 'nowrap'
                  }}
                  onClick={() => {
                    if (campaign.status === 'INCOMPLETED') {
                      setShowStopGeneration(true);
                    } else {
                      history.push(`/tactical-outreach/builder?campaign_id=${campaign.id}`)
                    }
                  }}
                >
                  {campaign.subCategory === 'MULTI_TOUCH_CAMPAIGNS'
                    ? 'Edit campaign parameters'
                    : 'Edit parameters'
                  }
                </Button>
                &nbsp;
                <LimitationWidget
                  type='TACTICAL_OUTREACH'
                />
                {!sideBarOpen && (
                  <>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <IconButton onClick={() => setSideBarOpen(true)}>
                      <SideBarOpenIcon />
                    </IconButton>
                  </>
                )}
              </Box>
            </Box>
          )}

          {campaign && email && (
            <ContentEditor
              campaign={campaign}
              email={email}
              editorState={editorState}
              setEmail={setEmail}
              isSaveDisabled={isSaveDisabled}
              handleSaveEmail={handleSaveEmail}
              handleGetCampaign={handleGetCampaign}
              setEditorState={setEditorState}
              setLoading={setLoading}
              setShowStopGeneration={setShowStopGeneration}
            />
          )}
        </Box>
        {campaign && email && (
          <SideBar
            open={sideBarOpen}
            email={email}
            campaign={campaign}
            isSaveDisabled={isSaveDisabled}
            setSideBarOpen={setSideBarOpen}
            setEmail={setEmail}
            setUnsavedDataModal={setUnsavedDataModal}
            handleGetCampaign={handleGetCampaign}
            setLoading={setLoading}
            setShowStopGeneration={setShowStopGeneration}
          />
        )}
      </Box>

      <ConfirmationModal
        open={unsavedDataModal.active}
        title={"You have unsaved data!"}
        description={"Do you want to save latest changes?"}
        onClose={() => setUnsavedDataModal({ active: false, targetEmail: null })}
        rejectBtnText={"No"}
        confirmBtnText={"Yes"}
        onReject={() => {
          setEmail(unsavedDataModal.targetEmail)
          setUnsavedDataModal({ active: false, targetEmail: null })
        }}
        onConfirm={async () => {
          await handleSaveEmail()
          setEmail(unsavedDataModal.targetEmail)
          setUnsavedDataModal({ active: false, targetEmail: null })
        }}
      />

      <ConfirmationModal
        open={leavingModal.active}
        title={
          emailGeneratingProcessActive
            ? "This action will stop email generating process!"
            : "Would you like to save your email before leaving?"
        }
        description={
          emailGeneratingProcessActive
            ? "Are you sure you want to stop the process and leave the page?"
            : null
        }
        onClose={() => setLeavingModal({
          active: false,
          nextLocation: null
        })}
        reversedButtons
        rejectBtnText={"Yes"}
        confirmBtnText={"No"}
        onReject={() => {
          setLoading(true);
          handleSaveEmail();
          setLeavingModal({
            ...leavingModal,
            active: false,
          });

          setTimeout(() => {
            history.push(leavingModal.nextLocation);
          }, 500);
        }}
        onConfirm={() => {
          if (emailGeneratingProcessActive) {
            setLeavingModal({
              active: false,
              nextLocation: null
            });
          } else {
            setLoading(true);
            setLeavingModal({
              active: false,
              nextLocation: null
            });

            setTimeout(() => {
              history.push(leavingModal.nextLocation);
            }, 500);
          }
        }}
      />

      <ConfirmationModal
        open={showGenerationFailed}
        title={"This campaign isn't completed yet"}
        description={"Continue the process of emails generating?"}
        onClose={() => setShowGenerationFailed(false)}
        rejectBtnText={"Continue"}
        onReject={() => {
          handleGetCampaign(campaign.id, email.id);
          setShowGenerationFailed(false);
        }}
      />

      <ConfirmationModal
        open={showStopGeneration}
        title={"You can't performe this action!"}
        description={campaign?.emails?.some(item => item.status === 'PENDING')
          ? "Wait for generating all emails to performe this action!"
          : "You have to generate all emails before to performe this action!"
        }
        onClose={() => setShowStopGeneration(false)}
        // confirmBtnText={"Close"}
        rejectBtnText={campaign?.emails?.some(item => item.status === 'PENDING')
          ? "Ok"
          : "Continue generation"
        }
        onReject={() => {
          if (campaign?.emails?.some(item => item.status === 'PENDING')) {
            setShowStopGeneration(false);
          } else {
            setShowStopGeneration(false);
            handleGetCampaign(campaign.id, email.id);
          }
        }}
      // onConfirm={() => setShowStopGeneration(false)}
      />
    </>
  );
};

export default TacticalOutreachEmail;
