import { Box, Hidden, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { RenderAutoFillChip } from "./CompanyProgressHeader.web";
import { ReactComponent as CompletedStepIcon } from '../../assets/icons/green_checkmark.svg';
import { ReactComponent as PebblesFullLogoIcon } from '../../assets/icons/pebbles_full_logo_black.svg';
import { checkPartiallyFilled } from "../../helpers/onboarding";
import SkipOnboardingConfirmationDialog from "./SkipOnboardingConfirmationDialog";
import StorageProvider from "../../framework/main/StorageProvider";
import LimitationWidget from "./LimitationWidget";

interface IProps {
  children: React.ReactNode;
  carousel?: React.ReactNode;
  hideBackBtn?: boolean;
  poweredByLogo?: boolean;
  poweredByText?: boolean;
  backgroundImage?: any;
  stickyHeader?: boolean;
  withScrollContainer?: boolean;
  onboardingProps?: any;
  isEdit?: boolean;
  back?: () => void;
}

const useStyles = makeStyles({
  container: {
    // height: "100vh",
    background: "#EBE2CF",
    // overflowY: "scroll",
    // overflowX: "hidden"
    overflow: 'hidden'
  },
  container1: {
    backgroundImage: `url(${require("./background.png")})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflow: "auto",
    height: "100vh"
  },
  header: {
    width: '100%',
    maxHeight: '70px',
    minHeight: '70px',
    // background: '#F2EDE2CC',
    padding: '20px 40px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerSticky: {
    position: 'sticky',
    top: 0,
    width: '100%',
    maxHeight: '70px',
    minHeight: '70px',
    background: '#EBE2CF',
    padding: '20px 40px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 1299
  },
  logo: {
    height: "25px",
    width: "125px",
    cursor: 'pointer',
    '& svg': {
      width: "100%",
      height: "100%"
    },
    "@media (min-width: 600px)": {
      height: "40px",
      width: "200px"
    }
  },
  leftSection: {
    position: "relative",
    display: "flex",
    height: "100vh",
    flexDirection: "column",
    alignItems: "flex-start",
    "@media (min-width: 600px)": {
      width: "50%",
      // flex: ".5",
      display: "flex",
      height: "100vh"
    }
  },
  leftSection2: {
    flex: "1",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "@media (min-width: 600px)": {
      display: "flex"
    }
  },
  leftContent: {
    display: "flex",
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  leftContent2: {
    width: "100%",
    height: "fit-content",
    display: "flex",
    marginTop: "50px",
    // flex:1,
    "@media (min-width: 600px)": {
      marginTop: 0,
      justifyContent: "center",
      alignItems: "center"
    }
  },
  iconBack: {
    zIndex: 1299,
    width: "48px",
    height: "48px",
    alignSelf: "flex-start",
    boxSizing: "border-box",
    position: 'absolute',
    left: '40px',
    top: '85px',
  },
  content: {
    height: 'calc(100vh - 70px)',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 1
  },
  content2: {
    height: 'calc(100vh - 155px)',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 1
  },
  scrollContainer: {
    width: '100%',
    padding: '0 0 80px 0',
    overflowY: 'auto',
  },
  rightSection: {
    display: "none",
    position: "relative",
    "@media (min-width: 600px)": {
      flex: ".5",
      display: "flex"
    }
  },
  img: {
    height: "100%",
    position: "absolute",
    left: "-34px",
    right: 0,
    width: "calc(100% + 34px)"
  },
  poweredByLogo: {
    color: "#000",
    textAlign: "center",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "19px"
  },
  poweredByText: {
    color: "#475569",
    textAlign: "center",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "18px"
  },
  poweredBox: {
    marginTop: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    position: "relative",
    paddingBottom: "28px"
  },
  canImg: {
    marginLeft: "8px",
    width: "100px",
    height: "17px"
  },
  tempImageWrapper: {
    boxSizing: "border-box",
    "-moz-box-sizing": "border-box",
    "-webkit-box-sizing": "border-box",
    width: "100%",
    height: "100%"
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover"
  },
  back: {
    width: 48,
    height: 48,
    cursor: "pointer",

    "@media (max-width: 767.98px)": {
      width: 24,
      height: 24
    }
  },
  carouselWrapper: {
    position: "fixed",
    top: 0,
    right: 0,
    width: "50%",
    bottom: 0
  },
  wrapper: {
    width: '870px',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "30px 0px 10px 0px",
    // position: 'sticky',
    // top: '20px',
    // zIndex: 999999999999,
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    cursor: 'pointer',
    "&:hover $inActiveTitle": {
      color: '#000',
    }
  },
  btn1: {
    height: '32px',
    width: '32px',
    backgroundColor: '#000',
    color: '#FFF',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn2: {
    height: '32px',
    width: '32px',
    backgroundColor: '#94A3B8',
    color: '#FFF',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  activeTitle: {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: '16px',
    color: '#000',
  },
  progressStepTitle: {
    marginLeft: "10px",
    marginTop: '3px'
  },
  inActiveTitle: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: '16px',
    color: '#475569',
  },
  bar1: {
    height: '4px',
    width: '80px',
    background: '#57BE99',
    marginRight: '5px',
    borderRadius: '20px',
    border: '1px solid transparent',
  },
  bar2: {
    height: '4px',
    width: '80px',
    background: '#000000',
    marginRight: '5px',
    borderRadius: '20px',
    border: '1px solid transparent',
  },
  bar2Transparent: {
    height: '4px',
    width: '80px',
    border: '1px solid #94A3B8',
    marginRight: '5px',
    borderRadius: '20px',
  },
  barWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: "10px"
  },
  autoFillChipWrapper: {
    position: 'absolute',
    top: '70px',
    left: 0,
    width: '100%',
    height: '104px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'visible',
    zIndex: 1600,
  },
  autoFillChipWrapper2: {
    zIndex: -1,
  },
  autoFillChip: {
    opacity: 0,
    width: '870px',
    borderRadius: '8px',
  },
  autoFillChipAnimation: {
    transition: 'all .3s ease-out',
  },
  autoFillChipHide: {
    opacity: 0,
    transform: 'translate(-8px, -104px)',
    boxShadow: '0px 0px 0px 0px #00000000',
  },
  autoFillChipShow: {
    opacity: 1,
    transform: 'translate(-8px, 0px)',
    boxShadow: '0px 24px 64px 0px #00000026',
  },
  fullFilled: {
    width: '32px',
    height: '32px',
    borderRadius: '200px',
    boxSizing: 'border-box'
  },
  empty: {
    width: '32px',
    height: '32px',
    borderRadius: '200px',
    background: 'transparent',
    boxSizing: 'border-box',
    color: '#475569',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '19px',
    border: '1px solid #475569',
    textAlign: 'center',
    padding: '6px 0px',
  },
  partiallyFilled: {
    width: '32px',
    height: '32px',
    borderRadius: '200px',
    background: '#000000',
    boxSizing: 'border-box',
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '19px',
    textAlign: 'center',
    padding: '7px 0px',
  }
});

const partsOfStep = [
  {
    full: 'mustHaveExpanded',
    short: 'must'
  },
  {
    full: 'shouldHaveExpanded',
    short: 'should'
  },
  {
    full: 'goodToHaveExpanded',
    short: 'good'
  },
];

const Layout = ({
  carousel,
  children,
  hideBackBtn,
  poweredByLogo = true,
  poweredByText = false,
  backgroundImage = false,
  stickyHeader = false,
  withScrollContainer = false,
  onboardingProps = null,
  isEdit = false,
  back = () => { },
}: IProps) => {
  const [autoFillFunction, setAutoFillFunction] = useState({
    callback: null
  });
  const [showAutoFillChip, setShowAutoFillChip] = useState(false);
  const [skipModalOpen, setSkipModalOpen] = useState(false);
  const [mouseOverChip, setMouseOverChip] = useState(false);
  const [timer, setTimer] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    handleScroll();
  }, []);

  useEffect(() => {
    if (onboardingProps) {
      window.addEventListener('mousemove', handleMouseMove);

      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
      };
    }
  }, [onboardingProps]);

  useEffect(() => {
    if (!showAutoFillChip && !mouseOverChip) {
      clearTimeout(timer);
    } else if (showAutoFillChip && !mouseOverChip) {
      const timeoutId = setTimeout(() => {
        setShowAutoFillChip(false);
      }, 3000);
      setTimer(timeoutId);
    } else if (showAutoFillChip && mouseOverChip && timer) {
      clearTimeout(timer);
    }
  }, [showAutoFillChip, mouseOverChip]);

  useEffect(() => {
    if (onboardingProps?.param) {
      const scrollContainer = document.getElementById('scrollContainer');

      scrollContainer.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });

      const index = onboardingProps.steps.findIndex(item => item.redirectUrl.includes(onboardingProps.param));

      onboardingProps.setActiveStep(index);

      setShowAutoFillChip(false);
    }
  }, [onboardingProps?.param]);

  const onBack = () => {
    if (!hideBackBtn) {
      back();
    }
  };

  const autoFillEmitter = (callback) => {
    setAutoFillFunction({
      callback: () => {
        setShowAutoFillChip(false);
        setMouseOverChip(false);
        callback();
      }
    });
  }

  const handleScroll = () => {
    if (onboardingProps) {
      const callback = (entries, observer) => {
        entries.forEach(entry => {

          if (entry.rootBounds) {
            if (entry.intersectionRect.height > 0 || entry.isIntersecting) {
              setShowAutoFillChip(false);
            } else {
              setShowAutoFillChip(true);
            }
          } else {
            setShowAutoFillChip(false);
          }
        });
      };

      const options = {
        root: null, // Use the viewport as the root
        rootMargin: '0px', // No margin around the root
        threshold: 0.01 // Trigger callback when 1% of the target is visible
      };

      const observer = new IntersectionObserver(callback, options);
      const autoFillChip = document.getElementById('autoFillChip');

      if (autoFillChip) {
        observer.observe(autoFillChip);
      } else {
        console.error('Element not found.');
      }
    }
  };

  const handleMouseOver = () => {
    setMouseOverChip(true);
  };

  const handleMouseOut = () => {
    setMouseOverChip(false);
    setShowAutoFillChip(true);
  };

  const handleMouseMove = () => {
    if (onboardingProps) {
      const searchParams = new URLSearchParams(window.location.search);
      const partOfStep = searchParams.get('partOfStep');

      const index = partsOfStep.findIndex(item => item.full === partOfStep);

      onboardingProps.setActivePartOfStep(index);
    }
  };

  const renderStep = (step, index) => {
    if (Object.values(step).every((item: any) => item.completed)) {
      return (
        <Box className={classes.fullFilled}>
          <CompletedStepIcon width={'100%'} height={'100%'} />
        </Box>
      )
    } else if (Object.values(step).some((item: any) => checkPartiallyFilled(item.data))) {
      return (
        <Box className={classes.partiallyFilled}>
          {index + 1}
        </Box>
      )
    } else {
      return (
        <Box className={classes.empty}>
          {index + 1}
        </Box>
      )
    }
  }

  const renderPartOfStep = (step, partOfStep) => {
    if (partOfStep.short === 'must' && step[partOfStep.short].completed) {
      return <Box className={classes.bar1} />
    } else if (partOfStep.short !== 'must' && checkPartiallyFilled(step[partOfStep.short].data)) {
      return <Box className={classes.bar1} />
    } else {
      return <Box className={classes.bar2Transparent} />
    }
  }

  return (
    <>
      <Box
        className={
          backgroundImage
            ? classes.container1
            : classes.container
        }
      >
        <Box
          className={
            carousel !== 'hide'
              ? classes.leftSection
              : classes.leftSection2
          }
        >
          <Box className={stickyHeader ? classes.headerSticky : classes.header}>
            <Box
              className={classes.logo}
              style={{
                // cursor: isEdit ? 'pointer' : 'default'
                cursor: 'default'
              }}
              // onClick={() => {
              //   if (isEdit && onboardingProps) {
              //     setSkipModalOpen(true);
              //   }
              // }}
            >
              <PebblesFullLogoIcon />
            </Box>
            {onboardingProps && (
              <Box className={classes.wrapper}>
                {onboardingProps.steps?.map((item: any, index: any) => (
                  <Box key={index}>
                    {onboardingProps.completedSteps && (
                      <Box
                        key={index}
                        className={classes.flexCenter}
                        onClick={() => {
                          // console.log('errors', onboardingProps.formRef.errors);

                          onboardingProps.onRedirect(item.redirectUrl);
                        }}
                      >
                        {renderStep(onboardingProps.completedSteps[item.id], index)}
                        <Typography className={classes.progressStepTitle}>
                          {onboardingProps.activeStep === index + 1 ? (
                            <Typography className={classes.activeTitle}>{item.label}</Typography>
                          ) : (
                            <Typography className={classes.inActiveTitle}>{item.label}</Typography>
                          )}
                        </Typography>
                      </Box>
                    )}
                    {onboardingProps.completedSteps && (
                      <Box
                        key={index}
                      >
                        <Box className={classes.barWrapper}>
                          {partsOfStep.map((partOfStep, partOfStepIndex) => (
                            renderPartOfStep(onboardingProps.completedSteps[item.id], partOfStep)
                          ))}
                        </Box>
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            )}
            <Box 
              className={classes.logo}
              style={{ width: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'default' }}
            >
              {isEdit && (
                <LimitationWidget
                  type='ONBOARDING'
                />
              )}
            </Box>
          </Box>
          {onboardingProps && (
            <Box className={classes.autoFillChipWrapper + ' ' + classes.autoFillChipAnimation + ' ' + (showAutoFillChip ? '' : classes.autoFillChipWrapper2)}>
              <Box
                className={classes.autoFillChip + ' ' + classes.autoFillChipAnimation + ' ' + (showAutoFillChip ? classes.autoFillChipShow : classes.autoFillChipHide)}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              >
                <RenderAutoFillChip onConfirm={autoFillFunction.callback} />
              </Box>
            </Box>
          )}
          <Box
            className={
              carousel !== 'hide'
                ? classes.leftContent
                : classes.leftContent2
            }
          >
            {/* <Box
              data-test-id="layoutBackButton"
              className={classes.iconBack}
              onClick={() => {
                if (isEdit) {
                  setSkipModalOpen(true);
                } else {
                  onBack();
                }
              }}
            >
              {!hideBackBtn && (
                <img
                  src={require("./button_back.svg").default}
                  alt="back"
                  className={classes.back}
                />
              )}
            </Box> */}
            <Box className={(poweredByLogo || poweredByText) ? classes.content2 : classes.content}>
              <Box
                id="scrollContainer"
                className={onboardingProps || withScrollContainer ? classes.scrollContainer : ''}
                onScroll={handleScroll}
              >
                {
                  React.Children.map(children, (child) => {
                    return React.cloneElement(child as any, { autoFillEmitter, hideBackBtn });
                  })
                }
              </Box>
            </Box>
          </Box>
          {poweredByLogo && (
            <div
              className={classes.poweredBox}
            >
              <Typography className={classes.poweredByLogo}>Powered by</Typography>
              <img
                src={require("./bottomLogo.png")}
                alt="can-img"
                className={classes.canImg}
              />
            </div>
          )}
          {poweredByText && (
            <div
              className={classes.poweredBox}
            >
              <Typography className={classes.poweredByText}>
                Only Gold Standard option is available for companies
              </Typography>
            </div>
          )}
        </Box>
        {carousel !== 'hide' && (
          <Box className={classes.rightSection}>
            <Box className={classes.carouselWrapper}>
              {carousel || (
                <Box className={classes.tempImageWrapper}>
                  <img
                    className={classes.image}
                    src="https://s3-alpha-sig.figma.com/img/754d/6c39/c180c1e1b1e7c40aa4b6dbc0959245ee?Expires=1700438400&Signature=nwca-ZzcbwIkxO8o6dnu9R8cp4zNTIAW22yNdiJKcaZjH6oi-Icpq6-Ga3sJfwbED1YHTZ22rzihawr27JziNtMewetqo98xASKtmOtEV8KUmXP9sSuCYRf0TEi~7ZZ0tCkw042RtV16iLAyVDGmDtlZfY~abCA4iGKOGTj26hbO4R5WCwFiJr~0sik0RLlbPZstLK9xlrkdW-xB4mUq93Yf1Gdbv9bd5tUdJXGnLpGziO4j6jwAKQhB-fKRLTXRbX68p13KICiDMidZYiNhgHUp1QedezFOCZ0ho7ePaA-QmnaCDaZj3cQdGoMAIdwdOijZGScaKs1kVRrsEVFbyw__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
                  />
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>

      <SkipOnboardingConfirmationDialog
        open={skipModalOpen}
        onClose={() => setSkipModalOpen(false)}
        onNo={() => {
          setSkipModalOpen(false);
          back();
        }}
        onYes={() => {
          setSkipModalOpen(false);
          back();
        }}
      />
    </>
  );
};

export default Layout;
