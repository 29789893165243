import { api, apiCall } from "../api";
import { ApiResponse } from "../types";

const subscriptionsAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getWallet: builder.query<any, any>({
      query: () => ({
        url: `/v1/license/wallet`,
        method: "get"
      }),
      keepUnusedDataFor: 0
    }),
    getTopUps: builder.query<any, any>({
      query: (type) => ({
        url: `/v1/license/top_ups?action_type=${type}`,
        method: "get"
      }),
      keepUnusedDataFor: 0
    }),
    createTopUpOrder: builder.query<any, any>({
      query: (body) => ({
        url: `/v1/license/top_up_orders`,
        method: "post",
        body
      })
    })
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getWallet = async (): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.getWallet);
};

export const getTopUps = async (type): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.getTopUps, type);
};

export const createTopUpOrder = async (body): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.createTopUpOrder, body);
};
